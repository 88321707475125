import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import store from './store'
import Popup from "vue-popup";
import  'vue-popup/lib/popup.css'
import {ACTION_APP_INIT} from "./store";
import {MUTATION_SHOW_LOADING_SPINNER} from "./store";
import { BootstrapVue } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import vuescroll from 'vuescroll';
import loader from "vue-ui-preloader";
import VueBus from 'vue-bus-manager'
import "@/assets/scss/main.scss";
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import Vuelidate from 'vuelidate'
import AOS from 'aos'
import 'aos/dist/aos.css'

import { VuejsDatatableFactory } from 'vuejs-datatable';
Vue.use(VuejsDatatableFactory);
Vue.use(vuescroll);
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VueBus)
Vue.use(loader)

Vue.component('date-picker', DatePicker);

Vue.filter("removeHtmlTags", function (value) {
    return value.replace(/<\/?[^>]+>/ig, " ");
});

// Add a request interceptor
let i =0;
let r=0;
let e=0;
axios.interceptors.request.use(function (config) {
  i++;
  store.commit(MUTATION_SHOW_LOADING_SPINNER,true);
  return config;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  r++;
  console.log('this is total i '+i+' and this is r and e ', r+e);
  if(r+e>=i){
    store.commit(MUTATION_SHOW_LOADING_SPINNER,false);
    r=0;
    i=0;
    e=0;
  }
  return response;
},
 function (error) {
   e++;
   console.log('This is from error and this is r and e ', r+e);
   if(e+r>=i){
    store.commit(MUTATION_SHOW_LOADING_SPINNER,false);
    r=0;
    i=0;
    e=0;
   }

  return Promise.reject(error);
});


VuejsDatatableFactory.useDefaultType(false)
    .registerTableType('datatable', tableType => tableType.mergeSettings({
      table: {
        class: 'table table-bordered vuejs-datatable',
      },
      pager: {
        classes: {
          pager: 'pagination',
          li: 'page-item',
          selected: 'active',
        },
        icons: {
          next: '<i class="fa fa-chevron-right" title="Next page"></i>',
          previous: '<i class="fa fa-chevron-left" title="Previous page"></i>',
        },
      },
    }));

Vue.config.productionTip = false
Vue.use(Popup);
Vue.use(BootstrapVue);
Vue.use(loader);

const app = new Vue({
  router,
  store,
  mounted() {
    AOS.init()
  },
  render: h => h(App)
});
store.dispatch(ACTION_APP_INIT, app);
