<template>
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 mb-4">
            <div class="foot-logo mb-3">
              <img :src="footerIcon" style="width: 30%" alt="">
            </div>
<!--            <p>Access to the insurance plans and large group pricing on this website is predicted upon joining Elevate Wellness, a national association with over 100,000 members</p>-->
            <ul>
              <li>Phone: <a href="javascript:void(0)">{{phone}}</a></li>
              <li>Email: <a href="javascript:void(0)">{{email}}</a></li>
            </ul>
          </div>
          <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 mb-4">
<!--            <p>Download The Member App</p>
            <div class="app-icon">
              <a v-bind:href ="ios" target="_blank"><img src="../assets/images/ios_icon.png" alt="iOS App" class="img-fluid"></a>
              <a v-bind:href ="android" target="_blank"><img src="../assets/images/google_icon.png" alt="Android App" class="img-fluid"></a>
            </div>
            <p>Click the Appropriate App</p>-->
            <p>For any assistance or issues, please report it to our ticketing system.</p>
            <a v-bind:href="ticket" class="ticket-btn">Ticketing System</a>
          </div>
          <div class="col-xxl-12">
            <p>Copyright &copy; 2022 | All Rights Reserved</p>
          </div>
        </div>
      </div>
    </footer>
</template>
<script>
import axios from "axios";
export default {
    name: 'SiteFooter',
  data: function () {
    return {
      phone: 'Loading..',
      email: 'Loading..',
      ticket: '',
      footerIcon: require(process.env.VUE_APP_FOOTER_LOGO),
      ios: require(process.env.VUE_APP_IOS_ICON),
      android: require(process.env.VUE_APP_ANDROID_ICON),
      // ios: 'https://apps.apple.com/us/app/corenroll-reps/id1527267392',
      // android: 'https://play.google.com/store/apps/details?id=com.neura.corenroll',
      open_type: '',
      group_id: '',
      agent_id: ''
    }
  },
  mounted () {
    let app = this;
    app.group_id = window.localStorage.getItem('group_id');
    app.agent_id = window.localStorage.getItem('agent_id');
    app.open_type = window.localStorage.getItem('open_type');
    app.eprocess = window.localStorage.getItem('eprocess');
    if (app.open_type === 'group' || app.eprocess === 'group') {
      app.getGroupHeaderInfo();
    } else {
      //open landing pages
      app.getAgentHeaderInfo();
    }
    app.getTicketLink();
  },
  methods : {
    getAgentHeaderInfo : function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-agent-landing-page-info', {
        params: {
          agent_id: app.agent_id
        },
      })
          .then(function (response) {
            app.phone = response.data.data.display_phone;
            app.email = response.data.data.display_email;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getGroupHeaderInfo: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE + '/get-group-landing-page-info', {
        params: {
          group_id: app.group_id
        },
      })
          .then(function (response) {
            app.phone = response.data.data.display_phone;
            app.email = response.data.data.display_email;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getTicketLink : function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-ticket-link')
          .then(function (response) {
            app.ticket = response.data.data;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
        listener:{
      'FromDirect'(){
      let app = this;
    app.group_id = window.localStorage.getItem('group_id');
    app.agent_id = window.localStorage.getItem('agent_id');
    if (app.open_type === 'group' || app.eprocess === 'group') {
      app.getGroupHeaderInfo();
    } else {
    app.getAgentHeaderInfo();
      }
    },
    }
  }
}
</script>
