<template>
    <section class="page-wrap">
        <div class="banner-wrap pb-5">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="breadcrumb">
                  <ul>
                    <li><a href="#" @click.prevent="homePage">Home</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xxl-5 col-xl-5 col-lg-5 col-12 mb-5">
                <h1>Welcome <br><span class="brand-color"><strong>{{userinfo.member_info.first_name}} {{userinfo.member_info.last_name}}</strong></span> to the <strong>Benefit Store</strong> <br> Let's <strong>Get Started</strong></h1>
                <div class="card-block">
                  <form action="" method="post">
                    <div class="form-group row">
                      <div class="col-xxl-6 mb-4">
                        <label for="">Choose Tier</label>
                        <select name="" class="custom-input" v-model="tier" id="tier" value="Choose Tier">
                          <option value="IO" selected>Member Only</option>
                          <option value="IS">Member + Spouse</option>
                          <option value="IC">Member + Child(ren)</option>
                          <!-- <option value="IC3">Member + Child(ren) > 3</option> -->
                          <option value="IF">Family</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-xxl-6 mb-4">
                        <button type="button" class="univ-btn" @click="submitAPI">Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-xxl-7 col-xl-7 col-lg-7 col-12">
                <div class="text-center">
                  <img :src="bannerImage" alt="banner-image" class="img-fluid">
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
</template>
<script>
    import axios from 'axios';
    export default {
        name: "Banner",
        data: function () {
            return {
                showSlide1: true,
                showSlide2: false,
                showSlide3: false,
                slideIndex: 'first',
                buttonText: 'SUBMIT',
                uid: '',
                userinfo: '',
                zip: '',
                dob: '',
                state: '',
                gender: '',
                is_smoker: '',
                tier: 'IO',
                eprocess: 'rep',
                agent_id: '10109',
                group_id: '77',
                apiResp: false,
                zipFlag: false,
                secondflag: false,
                thirdflag: false,
                zip_error_message: '',
                second_error_message: '',
                third_error_message: '',
                formErrorFlag: [],
                formErrors: [],
                platformId: '',
                enrollType: '',
                open_type: '',
                bannerImage: require(process.env.VUE_APP_BANNER_iMAGE)
            }
        },
        mounted() {
            let app = this;
            app.uid = app.$store.state.member.userid; // store not working in all cases - so disabled
            // let urlParams = new URLSearchParams(window.location.search);
            // let user_id = urlParams.get('uid');
            // app.uid = atob(user_id);
            app.getUserInfo();
            window.localStorage.setItem('eprocess', 'applicant');
            app.open_type = window.localStorage.getItem('open_type');

        },
        methods: {
            submitAPI: function () {
                // Submit API and
                // Navigate to plans page
                let app = this;
                axios.post(process.env.VUE_APP_API_BASE+'/add-filter-for-member', {
                    user_id: app.uid,
                    tier: app.tier,
                    open_type: app.open_type,
                })
                .then(function (response) {
                    if (response.data.status == 'success') {
                        window.localStorage.setItem('tempId', response.data.data.temp_enrollment_id);
                        window.localStorage.setItem('portal_id', response.data.data.portal_id);
                        app.$bus.$emit('getMenuList');
                        app.nextPage(true);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

            },
            getUserInfo: function () {
                let app = this;
                axios.get(process.env.VUE_APP_API_BASE+'/get-member-info', {
                    params: {
                        user_id: app.uid,
                    },
                })
                .then(function (response) {
                    app.userinfo = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
            nextPage: function() {
              window.localStorage.setItem('enroll_type', 'existing-member');
              window.localStorage.setItem('eprocess', 'applicant');
                this.$router.push('plans');
            },
            homePage: function(){
              if (this.open_type !== null && typeof(this.open_type) !== 'undefined') {
                if (this.referral !== null && typeof(this.referral) !== 'undefined') {
                  this.$router.push('/referrals?rid='+this.referral);
                } else {
                  this.$router.push('/');
                }
              } else {
                if (this.eprocess === 'rep') {
                  this.$router.push('/agent-home');
                }else if (this.eprocess === 'applicant') {
                  this.$router.push('/member-home');
                } else if (this.eprocess === 'group') {
                  this.$router.push('/group-home');
                }
              }
          }
        }
    }
</script>
