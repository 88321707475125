  <template>
    <section class="innerpage-wrap">
        <div class="container" ref="saveBen">
            <div class="split-title">
                <h1 class="brand-color">Beneficiary Information</h1>
            </div>
            <div class="enrollmentForm card-block">
                <div v-if="benData.length > 0">
                    <div class="memberAdded" >
                        <form class=""  method="post">
                        <ul>
                            <li v-for="(ben,index) in benData" v-bind:key="index">
                                Beneficiary #{{index+1}}: {{ben.first_name+' '+ben.last_name}}
                                <div class="AddedAction">
                                    <a href="#" v-b-tooltip.hover title="Edit" @click.prevent="showPopEdit(index)"><i class="fas fa-pen"></i></a>
                                    <a href="#" v-b-tooltip.hover :title="Delete_title" @click.prevent="removeBen(index)"><i class="fas fa-trash"></i></a>
                                </div>
                            </li>
                        </ul>
                        </form>

                    </div>
                </div>

                  <div class="depMsg" v-if="benData.length > 0">
                    <div class="msg-block" style="color:green" v-if="totalBen == 100" >Now You can Continue Enrollment</div>
                    <div class="msg-block" style="color:red" v-if="totalBen > 100" >Your total Beneficiary Percentage is {{totalBen}} and it Should not be more than 100%</div>
                    <div class="msg-block" style="color:red" v-if="totalBen < 100" >Your total Beneficiary Percentage is {{totalBen}} and it Should not be less than 100%</div>
                    <div class="msg-block mt-3" style="color:red" v-if="benData.length >= 3 && totalBen !== 100" >Maximum allowed number of beneficiaries is 3</div>
                  </div>
                <div class="depMsg"  v-else>
                  <div class="msg-block" style="color:#1a1a1a;" >Please add your beneficiaries before continuing.</div>

                </div>
                <div style="margin-top:1rem;" centered class="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12 mb-3" v-if="benData.length < 3">
                  <button @click="showPop" class="univ-btn" v-if="totalBen < 100" >+ Add Beneficiary</button>
                </div>
                <div class="error_message" v-if="mainErrorFlag == true">{{mainErrors}}</div>

                    <!-- beneficiary form slider or popup -->
                <div  v-show="visible && totalBen < 100" class="popups">
                    <div><h3>Beneficiary #{{benData.length+1}}</h3></div>
                  <form class="" action="index.html" method="post">
                    <div class="form-group row">
                      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.first_name.required == true">
                        <label>First Name <span class="required">*</span></label>
                        <input type="text"
                               class="custom-input"
                               @input="$v.formFields.first_name.value.$touch(); removeError();"
                               :class="{'form-control is-invalid':$v.formFields.first_name.value.$error || formErrors['first_name_message']}"
                               placeholder="Enter First Name"
                               v-model="formFields.first_name.value">
                        <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.first_name.value.required && $v.formFields.first_name.value.$error">
                          Field is required
                        </div>
                        <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.first_name.value.required && $v.formFields.first_name.value.$error">
                          Please enter valid first name
                        </div>
                        <div class="error_message" v-if="formErrorFlag == true">{{formErrors['first_name_message']}}</div>
                      </div>
                      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.last_name.required == true">
                        <label>Last Name <span class="required">*</span></label>
                        <input type="text"
                               class="custom-input"
                               placeholder="Enter Last Name"
                               @input="$v.formFields.last_name.value.$touch(); removeError();"
                               v-bind:class="{'form-control is-invalid':$v.formFields.last_name.value.$error || formErrors['last_name_message']}"
                               v-model="formFields.last_name.value">
                        <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.last_name.value.required && $v.formFields.last_name.value.$error">
                          Field is required
                        </div>
                        <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.last_name.value.required && $v.formFields.last_name.value.$error">
                          Please enter valid last name
                        </div>
                        <div class="error_message" v-if="formErrorFlag == true">{{formErrors['last_name_message']}}</div>
                      </div>
                      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.birthdate.required == true">
                        <label>Date of Birth <span class="required">*</span></label>
                        <date-picker
                            input-class="custom-input"
                            v-model="formFields.birthdate.value"
                            placeholder="mm/dd/yyyy"
                            :disabled-date="(date) => date >= new Date()"
                            @input="$v.formFields.birthdate.value.$touch(); removeError();"
                            :class="{'invalid':$v.formFields.birthdate.value.$error || formErrors['birthdate_message']}"
                            v-on:keyup="eventDob()"
                            format="MM/DD/YYYY"
                            value-type="format">
                          <template #input='{ props, events }'>
                            <input
                                v-bind='props'
                                size=10 maxlength=10
                                onkeyup="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                                v-on='events'>
                          </template>
                        </date-picker>
                        <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.birthdate.value.required && $v.formFields.birthdate.value.$error">
                          Field is required
                        </div>
                        <div class="error_message" v-if="formErrorFlag == true">{{formErrors['birthdate_message']}}</div>
                      </div>
                    </div>

                    <div class="form-group row">


                      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.relationship.required == true">
                        <label>Relationship <span class="required">*</span></label>
                        <b-form-select v-model="formFields.relationship.value"
                                       class="custom-input"
                                       @input="$v.formFields.relationship.value.$touch(); removeError();"
                                       :class="{'form-control is-invalid':$v.formFields.relationship.value.$error || formErrors['relationship_message']}"
                                       id="relation_ship_type">
                          <option value="null" selected disabled>Select Relationship</option>
                          <option v-for="item in relOptions" :value="item[0]" v-bind:key="item[0]">{{item[0]}}</option>
                        </b-form-select>
                        <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.relationship.value.required && $v.formFields.relationship.value.$error">
                          Field is required
                        </div>
                        <div class="error_message" v-if="formErrorFlag == true">{{formErrors['relationship_message']}}</div>
                      </div>
                      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.ssn.required == true">
                        <label>Social Security Number <span class="required">*</span></label>
                        <input type="number"
                               class="custom-input"
                               placeholder="Enter Social Security Number"
                               :class="{'form-control is-invalid':$v.formFields.ssn.value.$error || formErrors['ssn_message']}"
                               @input="$v.formFields.ssn.value.$touch();removeError();"
                               v-model="formFields.ssn.value"
                               min="0"
                               oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                               maxlength="9">
                        <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.ssn.value.required && $v.formFields.ssn.value.$error">
                          Field is required
                        </div>
                        <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.ssn.value.required && $v.formFields.ssn.value.$error">
                          Invalid Social Security Number
                        </div>
                        <div class="error_message" v-if="formErrorFlag == true">{{formErrors['ssn_message']}}</div>
                      </div>
                      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.beneficiary_percentage.required == true">
                        <label>Beneficiary Percentage (Upto 100%) <span class="required">*</span></label>
                        <input type="number"
                               class="custom-input"
                               placeholder="Enter Beneficiary Percentage"
                               :class="{'form-control is-invalid':$v.formFields.beneficiary_percentage.value.$error || formErrors['beneficiary_percentage_message']}"
                               @input="$v.formFields.beneficiary_percentage.value.$touch();removeError();"
                               v-model="formFields.beneficiary_percentage.value" v-on:keyup="nan_removel()"
                               oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="3"
                               max="100"
                               min="0">
                        <!--                                   oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="3">-->
                        <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.beneficiary_percentage.value.required && $v.formFields.beneficiary_percentage.value.$error">
                          Field is required
                        </div>
                        <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.beneficiary_percentage.value.required && $v.formFields.beneficiary_percentage.value.$error">
                          Beneficiary percentage should not be 0 or more than 100
                        </div>
                        <div class="error_message" v-if="formErrorFlag == true">{{formErrors['beneficiary_percentage_message']}}</div>
                      </div>
                    </div>


                    <div class="form-group row justify-content-end">
                      <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12">
                        <button class="submit univ-btn" type="button" v-if="totalBen < 100" name="button" @click="validateData('insert')" :disabled="submit">Submit</button>
                      </div>
                    </div>
                  </form>
                </div>

                <!-- beneficiary update form slider or popup -->
                <div  v-show="visibleEdit" class="popups">
                    <div><h4>Beneficiary #{{editBen+1}}</h4></div>
                  <form class="" action="index.html" method="post">
                    <div class="form-group row">
                      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.first_name.required == true">
                        <label>First Name <span class="required">*</span></label>
                        <input type="text"
                               class="custom-input"
                               @input="$v.formFields.first_name.value.$touch(); removeError();"
                               :class="{'form-control is-invalid':$v.formFields.first_name.value.$error || updateformErrors['first_name_message']}"
                               v-model="formFields.first_name.value">
                        <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.first_name.value.required && $v.formFields.first_name.value.$error">
                          Field is required
                        </div>
                        <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.first_name.value.required && $v.formFields.first_name.value.$error">
                          Please enter valid first name
                        </div>
                        <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['first_name_message']}}</div>
                      </div>
                      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.last_name.required == true">
                        <label>Last Name <span class="required">*</span></label>
                        <input type="text"
                               class="custom-input"
                               @input="$v.formFields.last_name.value.$touch(); removeError();"
                               v-bind:class="{'form-control is-invalid':$v.formFields.last_name.value.$error || updateformErrors['last_name_message']}"
                               v-model="formFields.last_name.value">
                        <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.last_name.value.required && $v.formFields.last_name.value.$error">
                          Field is required
                        </div>
                        <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.last_name.value.required && $v.formFields.last_name.value.$error">
                          Please enter valid last name
                        </div>
                        <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['last_name_message']}}</div>
                      </div>
                      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.birthdate.required == true">
                        <label>Date of Birth <span class="required">*</span></label>
                        <date-picker
                            input-class="custom-input"
                            v-model="formFields.birthdate.value"
                            placeholder="mm/dd/yyyy"
                            :disabled-date="(date) => date >= new Date()"
                            @input="$v.formFields.birthdate.value.$touch(); removeError();"
                            :class="{'invalid':$v.formFields.birthdate.value.$error || updateformErrors['birthdate_message']}"
                            v-on:keyup="eventDob()"
                            format="MM/DD/YYYY"
                            value-type="format">
                          <template #input='{ props, events }'>
                            <input
                                v-bind='props'
                                size=10 maxlength=10
                                onkeyup="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                                v-on='events'>
                          </template>
                        </date-picker>
                        <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.birthdate.value.required && $v.formFields.birthdate.value.$error">
                          Field is required
                        </div>
                        <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['birthdate_message']}}</div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.relationship.required == true">
                        <label>Relationship <span class="required">*</span></label>
                        <b-form-select v-model="formFields.relationship.value"
                                       class="custom-input"
                                       @input="$v.formFields.relationship.value.$touch(); removeError();"
                                       :class="{'form-control is-invalid':$v.formFields.relationship.value.$error || updateformErrors['relationship_message']}"
                                       id="relation_ship_type">
                          <option value="null" selected disabled>Select Relationship</option>
                          <option v-for="item in relOptions" :value="item[0]" v-bind:key="item[0]">{{item[0]}}</option>
                        </b-form-select>
                        <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.relationship.value.required && $v.formFields.relationship.value.$error">
                          Field is required
                        </div>
                        <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['relationship_message']}}</div>
                      </div>
                      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.ssn.required == true">
                        <label>Social Security Number <span class="required">*</span></label>
                        <input type="number"
                               class="custom-input"
                               placeholder="Enter Social Security Number"
                               :class="{'form-control is-invalid':$v.formFields.ssn.value.$error || updateformErrors['ssn_message']}"
                               @input="$v.formFields.ssn.value.$touch();removeError();"
                               oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                               v-model="formFields.ssn.value"
                               min="0"
                               maxlength="9">
                        <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.ssn.value.required && $v.formFields.ssn.value.$error">
                          Field is required
                        </div>
                        <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.ssn.value.required && $v.formFields.ssn.value.$error">
                          Invalid Social Security Number
                        </div>
                        <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['ssn_message']}}</div>
                      </div>
                      <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.beneficiary_percentage.required == true">
                        <label>Beneficiary Percentage (Upto 100%) <span class="required">*</span></label>
                        <input type="number"
                               class="custom-input"
                               :class="{'form-control is-invalid':$v.formFields.beneficiary_percentage.value.$error || updateformErrors['beneficiary_percentage_message']}"
                               @input="$v.formFields.beneficiary_percentage.value.$touch();removeError();"
                               placeholder="Enter Beneficiary Percentage"
                               v-model="formFields.beneficiary_percentage.value" v-on:keyup="nan_removel()"
                               min="0"
                               max="100"
                        >
                        <div class="form-group__message text-left ml-3 mt-2"
                             v-if="!$v.formFields.beneficiary_percentage.value.required && $v.formFields.beneficiary_percentage.value.$error">
                          Field is required
                        </div>
                        <div class="form-group__message  ml-3 mt-2" v-if="$v.formFields.beneficiary_percentage.value.required && $v.formFields.beneficiary_percentage.value.$error">
                          beneficiary percentage should not be 0 or more than 100.
                        </div>
                        <div class="error_message" v-if="updateformErrorFlag == true">{{updateformErrors['beneficiary_percentage_message']}}</div>
                      </div>
                    </div>

                    <div class="form-group row justify-content-end">
                      <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-12">
                        <button class="submit univ-btn" type="button" name="button"  @click="validateData('update')">Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
            </div>
            <div class="action-btn split-title mt-5">
                <router-link to="" @click.native="navigatePrevious" class="dark-btn">Back</router-link>
                <div class="action-next">
                    <router-link to="" @click.native="saveEnrollment" class="button-green">Save & Continue Later</router-link>
                    <router-link to="" class="button-brand" @click.native="saveData"
                      v-if="benData.length > 0 && totalBen === 100 "
                      :title="next_title"
                      v-b-tooltip.hover
                      :disabled="submit">Next</router-link>
                </div>
            </div>
        </div>
      <b-modal v-bind:hide-footer="true" id="code-send" centered size="md" >
        <template #modal-header>
          <div class="popUp-header" style="border-bottom: 0px !important">
            <div  v-if="saveSuccess">
              <h4 style="color: green"><b>Information Saved!!!</b> Check your email to continue</h4>
            </div>
            <div v-else>
              <h4>Please enter the alternate email you want to send Enrollment code to?</h4>
            </div>
            <b-button variant="close-btn" @click="$bvModal.hide('code-send')">
              <i class="fas fa-times"></i>
            </b-button>
          </div>
        </template>
        <b-container fluid>
          <div v-if="saveSuccess==false" class="input_box client_email">
            <input type="text"
                   class="custom-input"
                   :class="{'form-control is-invalid': email && !isEmailValid || isEmailNull}"
                   @input="emailValid(); removeError();"
                   v-model="email" value="" />
            <div class="form-group__message  ml-3 mt-2" v-if="email && !isEmailValid || isEmailNull">
              Enter valid email address
            </div>
            <div class="error_message" v-if="formErrorFlag == true">{{formErrors['email_message']}}</div>
          </div>
          <div class="popUp-details text-center">
            <div v-if="saveSuccess" class="action-btn text-center mt-4">
              <b-button style="width:100%;"  class="button-brand mx-1" @click="$bvModal.hide('code-send')" data-dismiss="modal">Done</b-button>
            </div>
            <div v-else class="action-btn text-center mt-4">
              <b-button style="width:40%;"  class="dark-btn mx-1" @click="$bvModal.hide('code-send')">Back</b-button>
              <b-button style="width:40%;"  class="button-brand mx-1" @click="saveAndContinueEnrollment" data-dismiss="modal">Send Code</b-button>
            </div>
          </div>
        </b-container>

      </b-modal>
    </section>
</template>

<script>
import axios from 'axios';
import html2canvas from 'html2canvas';
import {required} from "vuelidate/lib/validators";
import {checkSsn, validName, checkEmail, checkBenefitPercentage} from "../../utils/validators";
export default {
  name: "BeneficiaryInformation",
  data: function () {
    return {
      tempId: '',
      beneficiary_percentage:'',
      formFields: {
        first_name: {
          value: '',
          required: false,
          read_only: false,
          error: false,
          message: '',
        },
        middle_name: {
          value: '',
          required: false,
          read_only: false,
          error: false,
          message: '',
        },
        last_name: {
          value: '',
          required: false,
          read_only: false,
          error: false,
          message: '',
        },
        birthdate: {
          value: '',
          required: false,
          read_only: false,
          error: false,
          message: '',
        },
        ssn: {
          value: '',
          required: false,
          read_only: false,
          error: false,
          message: '',
        },
        relationship: {
          value: null,
          required: false,
          read_only: false,
          error: false,
          message: '',
        },
        beneficiary_percentage: {
          value: 0,
          required: false,
          read_only: false,
          error: false,
          message: '',
        }
      },
      benData: [],
      ben:0,
      enrollment_type: '',
      relOptions: [],
      category: '',
      visible: false,
      visibleEdit: false,
      editBen: 0,
      totalBen: 0,
      formErrors: [],
      submit:false,
      updateformErrors: [],
      formErrorFlag: false,
      mainErrorFlag: false,
      mainErrors: '',
      updateformErrorFlag: false,
      savePopup: false,
      email: '',
      saveSuccess: false,
      isEmailNull: false,
      isEmailValid: false,
      beneficiary_id: '',
      disable_delete: false,
      Delete_title: 'Delete',
      next_title: 'Click to continue enrollment',
    }
  },
  mounted() {
    let app = this;
    app.tempId = window.localStorage.getItem('tempId');
    app.enrollment_type = window.localStorage.getItem('enroll_type');
    app.getFields();
    app.getRelations();
    app.fetchExiInfo();
    app.totalPercentage();
  },
  validations: {
    formFields: {
      first_name: {
        value: {
          required,
          validName
        }
      },
      last_name: {
        value: {
          required,
          validName
        }
      },
      relationship: {
        value: {
          required
        }
      },
      birthdate: {
        value: {
          required
        }
      },
      ssn: {
        value: {
          required,
          checkSsn
        }
      },
      beneficiary_percentage: {
        value: {
          required,
          checkBenefitPercentage
        }
      }
    },
  },
  methods: {
    totalPercentage:function() {
      for(let i = 0; i < this.benData.length; i++){
        this.totalBen += (parseFloat(this.benData[i].beneficiary_percentage));
      }
      return this.totalBen;
    },
    checkValue: function(str, max) {
      if (str.charAt(0) !== '0' || str == '00') {
        var num = parseInt(str);
        if (isNaN(num) || num <= 0 || num > max) num = 1;
        str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
      }
      return str;
    },
    eventDob: function() {
      let app = this;
      var input = app.formFields.birthdate.value;
      if (/\D\/$/.test(input)) input = input.substr(0, input.length - 1);
      var values = input.split('/').map(function(v) {
        return v.replace(/\D/g, '')
      });
      if (values[0]) values[0] = app.checkValue(values[0], 12);
      if (values[1]) values[1] = app.checkValue(values[1], 31);
      var output = values.map(function(v, i) {
        return v.length == 2 && i < 2 ? v + '/' : v;
      });
      app.formFields.birthdate.value = output.join('').substr(0, 10);
    },
    getFields: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-form-fields-beneficiary-info', {
        params: {
          enrollment_id: app.tempId,
        }
      })
          .then(function (response) {
            console.log(response.data.data);
            if (response.data.status == 'success') {
              response.data.data.result.forEach(function(item) {
                app.formFields[item.field_name] = item;
              });
            }
            // app.fetchInfo();
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    nan_removel:function(){
      let nan_value=this.formFields.beneficiary_percentage.value;
      if(isNaN(nan_value)){
        let nan_length=nan_value.length-1;
        nan_value= nan_value.substring(0, nan_length) + "" + nan_value.substring(nan_value.length, nan_value.length);
      }
      if(isNaN(nan_value)){
        nan_value='';
      }
      this.formFields.beneficiary_percentage.value=nan_value;
    },
    fetchInfo: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-enrollment-temp-info', {
        params: {
          enrollment_id: app.tempId,
          column: 'benificiary_info',
        }
      })
          .then(function (response) {
            // let fetchedData = [];
            if (response.data.status == 'success') {
              // app.benData = response.data.data.result;
              if (response.data.data.result == null) {
                app.benData = [];
              }else{
                app.benData = response.data.data.result;
                app.totalPercentage();
              }
              // app.benData.push([response.data.data.result]);
              // for(var i in response.data.data.result) {
              //     fetchedData.push([i, response.data.data.result [i]]);
              // }
              // console.log(fetchedData);
              // fetchedData.forEach(function(item) {
              //     let key = item[0];
              //     let val = item[1];
              //     app.formFields[key]['value'] = val;
              // });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    fetchExiInfo: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-beneficiaries-existing-user-enrollment', {
        params: {
          enrollment_id: app.tempId,
        }
      })
          .then(function (response) {
            // let fetchedData = [];
            if (response.data.status == 'success') {
              // app.benData = response.data.data.result;
              if (response.data.data.result == null) {
                app.benData = [];
              }else{
                Object.values(response.data.data.result).forEach(function (item) {
                  if(item.dob){
                    item.birthdate = item.dob;
                  }
                  app.benData.push(item);
                })
                app.totalPercentage();
              }
            }
          })
          .catch(function (error) {
            if (error.response.data.status == 'error') {
              app.fetchInfo();
            }
          });
    },
    getRelations: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-beneficiary-relationships')
          .then(function (response) {
            console.log(response.data.data);
            if (response.data.status == 'success') {
              for(var i in response.data.data.result) {
                app.relOptions.push([response.data.data.result [i]]);
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    removeBen: function(index) {
      let app = this;
      if(app.disable_delete == true){
        return false;
      }
      else{
        app.benData.splice(index, 1);
        app.totalBen = 0;
        app.totalPercentage();
      }
    },
    validateData: function(action) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log('***** Inside Save Data Invalid*****')
        return false
      } else {
        let app = this;
        let params = {
          beneficiary_id:         app.beneficiary_id,
          first_name:             app.formFields.first_name.value,
          last_name:              app.formFields.last_name.value,
          birthdate:              app.formFields.birthdate.value,
          ssn:                    app.formFields.ssn.value,
          relationship:           app.formFields.relationship.value,
          beneficiary_percentage: app.formFields.beneficiary_percentage.value,
        };
        //discard null values
        for (const key of Object.keys(params)) {
          if (params[key] === null) {
            delete params[key];
          }
        }
        app.formErrors = [];
        app.updateformErrors = [];
        app.formErrorFlag = false;
        app.updateformErrorFlag = false;
        let el = '';
        if (action == 'update') {
          el = app.$refs.saveBen;
          app.submit = false
        } else {
          el = app.$refs.saveBen;
          // app.submit = true
        }
        (async () => {
          this.saveScreenShot((await html2canvas(el)).toDataURL('image/png'), 'beneficiary_info_' + action + params.first_name);
        })();
        //empty the new form values
        axios.post(process.env.VUE_APP_API_BASE + '/validate-single-beneficiary', params)
            .then(function (response) {
              if (response.data.status == 'success') {
                console.log("success");
                if (action == 'update') {
                  app.disable_delete=false;
                  app.Delete_title='Delete';
                  app.next_title= 'Click to continue enrollment';
                  app.benData[app.editBen] = params;
                  app.closePopEdit(app.editBen);
                  app.totalBen = 0;
                  app.getFields();
                }else{
                  app.benData.push(params);
                  app.closePop();
                  app.totalBen = 0;
                }
                app.formErrors = [];
                app.updateformErrors = [];
                app.formErrorFlag = false;
                app.updateformErrorFlag = false;
                app.submit = false;
                app.formFields.first_name.value = '';
                app.formFields.middle_name.value = '';
                app.formFields.last_name.value = '';
                app.formFields.birthdate.value = '';
                app.formFields.ssn.value = '';
                app.formFields.relationship.value = null;
                app.formFields.beneficiary_percentage.value = '';
                app.totalPercentage();
                app.scrollTop();
                app.visible = false;
                app.$v.$reset();
              }
            })
            .catch(function (error) {
              if (error.response.data.status == 'error') {
                error.response.data.data.forEach(function(item) {
                  if (action == 'update') {
                    console.log(item.error_message);
                    app.updateformErrorFlag = true;
                    // app.submit=true;
                    app.updateformErrors[item.target_element+'_message'] = item.error_message;
                  } else {
                    app.formErrorFlag = true;
                    // app.submit=true;
                    app.formErrors[item.target_element+'_message'] = item.error_message;
                  }
                });
              }
            });
      }
    },
    saveData: function () {
      if(this.disable_delete == true){
        return false;
      }
      else{
        const el = this.$refs.saveBen;
        (async () => {
          this.saveScreenShot((await html2canvas(el)).toDataURL('image/png'), 'beneficiary_info');
          this.screenShotData = (await html2canvas(el)).toDataURL();
        })();
        // Submit API
        let app = this;
        app.formErrors= [];
        app.updateformErrors= [];
        app.formErrorFlag= false;
        app.updateformErrorFlag= false;
        app.mainErrorFlag = false;
        app.mainErrors = '';
        app.submit= true
        //empty the new form values
        axios.post(process.env.VUE_APP_API_BASE+'/save-beneficiary-info', {enrollment_id:app.tempId, beneficiaries: app.benData})
            .then(function (response) {
              if (response.data.status == 'success') {
                app.saveAndContinue();
              }
            })
            .catch(function (error) {
              if (error.response.data.status == 'error') {
                error.response.data.data.forEach(function(item) {
                  app.mainErrorFlag = true;
                  app.mainErrors = item.error_message;
                });
              }
            });
      }
    },
    saveAndContinueEnrollment() {
      if (!this.isEmailValid) {
        this.isEmailNull = true;
      } else {
        let app = this;
        let data = {
          enrollment_id: app.tempId,
          email: app.email,
          tab: 'benificiary_info',
          data: app.benData
        }
        app.formErrorFlag = false;
        app.formErrors = [];
        axios.post(process.env.VUE_APP_API_BASE + '/save-enrollment', data)
            .then(function (response) {
              if (response.data.status == 'success') {
                app.saveSuccess = true;
                console.log("success");
                // app.saveAndContinue();
              }
            })
            .catch(function (error) {
              if (error.response.data.status == 'error') {
                console.log('error');
                error.response.data.data.forEach(function (item) {
                  app.formErrorFlag = true;
                  app.formErrors[item.target_element + '_message'] = item.error_message;
                });
                console.log(app.formErrors);
              }
            });
      }
    },
    saveEnrollment : function () {
      let app = this;
      app.$bvModal.show('code-send');
    },
    closePopup : function () {
      let app = this;
      app.savePopup = false;
    },
    saveAndContinue()
    {
      //Update APIs
      //Navigate to next page
      const el = this.$refs.saveBen;
      (async () => {
        this.saveScreenShot((await html2canvas(el)).toDataURL('image/png'), 'beneficiary_info');
        this.screenShotData = (await html2canvas(el)).toDataURL();
      })();
      if (localStorage.getItem("navto") === null || localStorage.getItem("navto") === undefined || localStorage.getItem("navto") === '') {
        this.$emit('comEnvNavigation', this.$route.name);
      } else {
        window.localStorage.removeItem('navto');
        this.$router.push('review-confirm');
      }
    },
    navigatePrevious() {
      //Update APIs
      //Navigate to next page
      this.$emit('comEnvNavigationBack', this.$route.name);
    },
    saveScreenShot (image, name) {
      console.log(image)
      console.log(name)
      let app = this;
      let data = {
        "enrollment_id" : app.tempId,
        "name" : name,
        "image" : image
      };
      let config = {
        method: 'post',
        url: process.env.VUE_APP_API_BASE+'/save-screenshot',
        headers: { },
        data : data
      };
      axios(config)
          .then(function (response) {
            if (response.data.status == 'success') {
              console.log('screenshot saved');
            }
          })
          .catch(function (error) {
            console.log('screenshot not saved');
            console.log(error);
          });
    },
    showPop(){
      let app = this;
      app.visibleEdit=false;
      app.formFields.first_name.value = '';
      app.formFields.middle_name.value = '';
      app.formFields.last_name.value = '';
      app.formFields.birthdate.value = '';
      app.formFields.ssn.value = '';
      app.formFields.relationship.value = null;
      app.formFields.beneficiary_percentage.value = '';
      app.visible=true;
      app.updateformErrors= [];
      app.updateformErrorFlag= false;
      app.$v.$reset();
    },
    closePop(){
      this.visible=false;
    },
    showPopEdit(val){
      let app = this;
      app.disable_delete = true;
      app.beneficiary_id = null;
      app.next_title = 'Please submit the edited form first to continue'
      app.Delete_title = 'Please submit the edited form first to delete';
      app.closePop();
      app.editBen = val;
      app.beneficiary_id = app.benData[val]['beneficiary_id'];
      app.formFields.first_name.value = app.benData[val]['first_name'];
      app.formFields.middle_name.value = app.benData[val]['middle_name'];
      app.formFields.last_name.value = app.benData[val]['last_name'];
      app.formFields.birthdate.value = app.benData[val]['birthdate'];
      app.formFields.ssn.value = app.benData[val]['ssn'];
      app.formFields.relationship.value = app.benData[val]['relationship'];
      app.formFields.beneficiary_percentage.value = app.benData[val]['beneficiary_percentage'];
      app.visibleEdit=true;
      app.formErrors= [];
      app.formErrorFlag= false;
    },
    closePopEdit(val){
      let app = this;
      console.log(val);
      this.editBen = 0;
      app.formFields.first_name.value = '';
      app.formFields.middle_name.value = '';
      app.formFields.last_name.value = '';
      app.formFields.birthdate.value = '';
      app.formFields.ssn.value = '';
      app.formFields.relationship.value = '';
      app.formFields.beneficiary_percentage.value = '';
      this.visibleEdit=false;
    },
    scrollTop(){
      window.scrollTo(0,0);
    },
    removeError () {
      this.formErrors = []
      this.updateformErrors = []
    },
    emailValid () {
      this.isEmailValid = checkEmail(this.email)
      this.isEmailNull = !checkEmail(this.email)
    }
  },
}
</script>

<style scoped>
</style>
