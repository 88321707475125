<template>
  <div class="page-wrap receiptPage">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xxl-6">
          <div class="Thanksimg mb-4">
            <img :src="thankYouImage" alt="Corenroll">
          </div>
          <h2>
            <span class="head_grey">Dear {{name}},<br>Thank you,</span>
          </h2>
          <p>Your confirmation number is #{{policyID}}. A receipt of your enrollment will be sent to you and also to the client with the subject "Enrollment Receipt"</p>
          <div class="rollback-btn">
            <button class="backHome" type="button" name="button" @click="homePage">Return to homepage</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios';

export default {
    name: "Confirmation",
    data: function () {
      return {
        policyID: '',
        name: '',
        tempId: '',
        eprocess: '',
        thankYouImage: require(process.env.VUE_APP_THANK_YOU)
      }
    },
    mounted() {
      let app = this;
      app.tempId = window.localStorage.getItem('tempId');
    app.eprocess = window.localStorage.getItem("eprocess");
    app.open_type = window.localStorage.getItem("open_type");
    app.referral = window.localStorage.getItem("referral");
      app.checkEprocess();
      app.fetchEff();
    },
    methods: {
        fetchEff: function () {
            let app = this;
            axios.get(process.env.VUE_APP_API_BASE+'/get-receipt', {
            params: {
                enrollment_id: app.tempId,
            }
            })
            .then(function (response) {
                console.log(response);
                if (response.data.status == 'success') {
                  app.policyID = response.data.data.policy_id;
                  app.name = response.data.data.name;
                  window.localStorage.removeItem('tempId');
                  window.localStorage.removeItem('dep_sync_temp_id');
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        },
        checkEprocess: function() {
          let app = this;
          axios.get(process.env.VUE_APP_API_BASE+'/get-enrollment-temp-info', {
            params: {
              enrollment_id: app.tempId,
              column: 'eprocess',
            }
          })
          .then(function (response) {
            console.log(response.data.data.result);
            if (response.data.status == 'success') {
              app.eprocess = response.data.data.result;
            }

          })
          .catch(function (error) {
            console.log(error);
          });
        },
        homePage: function () {
      if (this.open_type !== null && typeof this.open_type !== "undefined") {
        if (this.referral !== null && typeof this.referral !== "undefined") {
          this.$router.push("/referrals?rid=" + this.referral);
        } else {
          this.$router.push("/");
        }
      } else {
        if (this.eprocess === "rep") {
          this.$router.push("/agent-home");
        } else if (this.eprocess === "applicant") {
          this.$router.push("/member-home");
        } else if (this.eprocess === "group") {
          this.$router.push("/group-home");
        }
      }
    },


    }
}
</script>

<style scoped>

</style>
