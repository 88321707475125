<template>
  <section class="page-wrap">
    <div class="banner-wrap">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb">
              <ul>
                <li><a href="#" @click.prevent="homePage">Home</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xxl-5 col-xl-5 col-lg-5 col-12 mb-5">
            <div>
              <h1>Welcome <br><span class="brand-color"><strong>{{ agent_name }}</strong></span> to the <strong>Benefit Store</strong> <br> Let's <strong>Get Started</strong></h1>
              <div  v-if="!isHiddenPortal1">
                <span class="banner-ques">Who do you want to enroll for?</span>
                <div class="enroll-btn">
                  <a href="javascript:void(0);" class="dark-btn" v-on:click="isHiddenPortal1 = true">New Member</a>
                  <router-link to="/agent-select-member" class="brand-btn">Current Member</router-link>
                </div>
              </div>
            </div>
            <div class="card-block" v-if="isHidden">
              <form action="">
                <div class="form-group row">
                  <div class="col-xxl-6 mb-4">
                    <label for="">Zip Code</label>
                    <input type="text"
                           name=""
                           placeholder="Enter your Zip Code"
                           class="custom-input">
                  </div>
                  <div class="col-xxl-6 mb-4">
                    <label for="">Gender</label>
                    <select name=""
                            id=""
                            class="custom-input">
                      <option value="null">Select Gender</option>
                      <option value="">Male</option>
                      <option value="">Female</option>
                      <option value="">Others</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xxl-6 mb-4">
                    <label for="">Choose Tier</label>
                    <select name=""
                            id=""
                            class="custom-input">
                      <option value="null">Choose Tier</option>
                      <option value="">Tier 1</option>
                      <option value="">Tier 2</option>
                      <option value="">Tier 3</option>
                    </select>
                  </div>
                  <div class="col-xxl-6 mb-4">
                    <label for="">DOB</label>
                    <date-picker
                        input-class="custom-input"
                        placeholder="Choose DOB"
                        type="date"
                        v-model="value"

                        value-type="format"></date-picker>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xxl-6 mb-4">
                    <button class="dark-univ-btn" v-on:click="isHidden = false">Back</button>
                  </div>
                  <div class="col-xxl-6 mb-4">
                    <button type="submit" class="univ-btn">Submit</button>
                  </div>
                </div>
              </form>
            </div>
            <div v-if="!isHiddenPortal2">
              <div class="card-block" v-if="isHiddenPortal1">
                <form action="">
                  <div class="col-12 mb-4">
                    <label for="">Choose Enrollment Portal</label>
<!--                    <select name="" class="custom-input" v-model="platformId" id="platformId">
                      <option v-for="item in platforms" :value="item.platform_group_id" v-bind:key="item.platform_group_id">{{item.platform_name}}</option>
                    </select>-->
                    <b-form-select name="" class="custom-input"
                                   :options="portalList"
                                   @input="preservePortalInfo(portal)"
                                   v-model="portal" id="platformId">
                    </b-form-select>
                    <p style="color:red;" v-if="platformError['error'] == true">{{platformError['message']}}</p>
                  </div>
                  <div class="enroll-btn agent-btn row">
                    <div class="col-xxl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                      <a href="javascript:void(0);" class="dark-btn mb-3" v-on:click="isHiddenPortal1 = false">Back</a>
                    </div>
                    <div class="col-xxl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                      <a href="javascript:void(0);" @click.prevent="choosePortal()"  class="brand-btn  mb-3">Submit</a>
                    </div>

                  </div>
                </form>
              </div>
            </div>
            <div class="card-block" v-if="isHiddenPortal2">
              <form action="">
                <div class="col-12 mb-4">
                  <a href="javascript:void(0);" class="dark-btn text-center w-100" @click="nextPageDefault">Start New Member Enrollment</a>
                  <br>
                  <br>
                  <h6 class="text-center"> OR </h6>
                  <br>
                  <label for="">Choose an option to enroll a new member:</label>
                  <select name=""
                          class="custom-input"
                          @click="getSubGroupList()"
                          v-model="group_id" id="group_id">
                    <option v-for="item in groupData"
                            :value="item.gid"
                            v-bind:key="item.gid">{{item.gname}}
                    </option>
                  </select>
                  <div class="form-group__message text-left ml-3 mt-2"  v-if="groupInvaild">
                    Choose enroll for new member
                  </div>
                  <p style="color:red;" v-if="platformError['error'] == true">{{groupErr['message']}}</p>
                </div>
                <br>
                <div class="sub-group-container" v-if="subGroupList.length !== 0">
                  <label for="">Choose sub group:</label>
                  <select name=""
                          class="custom-input"
                          v-model="subGroupId" id="sub_group_id">
                    <option v-for="(item, index) in subGroupList" :value="item.sub_group_id" v-bind:key="index">{{item.name}}</option>
                  </select>
                </div>
                <div class="enroll-btn agent-btn row">
                  <div class="col-xxl-6 col-6">
                    <a href="javascript:void(0);" class="dark-btn mb-3" v-on:click="isHiddenPortal2 = false; emptyExistingInfo();">Back</a>
                  </div>
                  <br>
                  <div class="col-xxl-6 col-6">
                    <a href="javascript:void(0);" @click="nextPage"  class="brand-btn  mb-3">Submit</a>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-xxl-7 col-xl-7 col-lg-7 col-12">
            <div class="text-center">
              <img :src="bannerImage" alt="banner-image" class="img-fluid">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
export default {
  name: "AgentHome",
  data: function () {
    return {
      groupData: [],
      agent_id: '',
      group_id: '',
      visible: false,
      groupErr: [],
      // platforms: [],
      portal: {},
      platformError: [],
      platformId: '',
      portalPop: false,
      platformPopup: false,
      agent_name: '',
      isHidden: false,
      groupInvaild: false,
      isHiddenPortal1: false,
      isHiddenPortal2: false,
      bannerImage: require(process.env.VUE_APP_BANNER_iMAGE),
      subGroupList: [],
      subGroupId: null,
      existingGroupId: null,
      portalList: []
    }
  },
  mounted() {
    let app = this;
    // app.getMember();
    app.agent_id = app.$store.state.agent.agent_id;
    app.agent_name = app.$store.state.agent.agent_fname + ( (app.$store.state.agent.agent_mname != null) ? ' ' + app.$store.state.agent.agent_mname : '') + ' ' + app.$store.state.agent.agent_lname;
    app.getGroups();
    app.getPlatforms()
    window.localStorage.setItem('eprocess', 'rep');
  },
  methods: {
    getGroups: function() {
      let app = this;
      // let agent_id = app.$route.params.id;
      axios.get(process.env.VUE_APP_API_BASE+'/get-agent-groups', {
        params: {
          agent_id: app.agent_id,
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.groupData = response.data.data;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getPlatforms: function() {
      let app = this;
      // let agent_id = app.$route.params.id;
      axios.get(process.env.VUE_APP_API_BASE+'/get-enrollment-platforms', {
        params: {
          agent_id: app.agent_id,
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.platforms = response.data.data;
              app.platforms.forEach( element => {
                let option = {
                  value: element,
                  text: element.platform_name
                }
                app.portalList.push(option)
              })
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    choosePortal: function() {
      console.log(this.platformId)
      let app = this;
      app.platformError = [];
      if (app.portal.platform_group_id) {
        app.platformError['error'] = false;
        app.platformPopup = false;
        app.isHiddenPortal1 = true;
        app.isHiddenPortal2 = true;
        window.localStorage.setItem('portal_id', this.portal.platform_group_id);
      }else{
        app.platformError['error'] = true;
        app.platformError['message'] = "Please choose a platform";
      }
    },
    nextPage: function() {
      if (!this.group_id) {
        this.groupInvaild = true;
      } else {
        this.platformPopup = false;
        this.visible = true;
        this.groupErr = [];
        if (this.group_id == '') {
          this.groupErr['error'] = true;
          this.groupErr['message'] = "Please choose a group";
        } else {
          window.localStorage.setItem('group_id', this.group_id);
          window.localStorage.setItem('enroll_type', 'new-group-member');
          window.localStorage.setItem('eprocess', 'rep');
          this.$bus.$emit('getMenuList');
          window.localStorage.removeItem('tempId')
          this.$router.push('/home');
        }
      }
    },
    nextPageDefault: function() {
      window.localStorage.setItem('group_id', this.portal.platform_group_id);
      window.localStorage.setItem('enroll_type', 'new-member');
      window.localStorage.setItem('eprocess', 'rep');
      this.$bus.$emit('getMenuList');
      this.$router.push('/home');
    },
    reloadPage: function(pid) {
      this.getPlanDetails();
      this.getPlanPricing();
      this.$router.push({name: 'plan', params: {id: pid}});
    },
    showPop(){
      this.portalPop=false;
      this.platformPopup=true;
    },
    closePop(){
      this.visible=false;
    },
    closePopPlatforms(){
      this.platformPopup=false;
    },
    closeportalPop(){
      this.portalPop=false;
    },
    memberPage: function(){
      window.localStorage.setItem('enroll_type', 'existing-member');
      this.$router.push('/agent-select-member');
    },
    getSubGroupList () {
      let app = this;
      if (app.group_id) {
        if (app.group_id !== app.existingGroupId) {
          // let agent_id = app.$route.params.id;
          axios.get(process.env.VUE_APP_API_BASE + '/get-sub-group', {
            params: {
              group_id: app.group_id,
            }
          })
              .then(function (response) {
                if (response.status == 204) {
                  app.subGroupList = []
                  window.localStorage.removeItem('subGroupId')
                }
                if (response.data.status == 'success') {
                  app.subGroupList = response.data.data
                }
              })
              .catch(function (error) {
                console.log(error);
              });
            app.existingGroupId = app.group_id
        }
      }
    },
    emptyExistingInfo () {
      let app = this;
      app.groupData = [],
      app.subGroupList = []
      app.group_id = null
      app.subGroupId = null
      app.existingGroupId = null
      window.localStorage.removeItem('subGroupId')
      app.getGroups();
    },
    preservePortalInfo(portalInfo) {
      window.localStorage.setItem('portal_code', portalInfo.platform_code);
      window.localStorage.setItem('portal_name', portalInfo.platform_name);
    },
    homePage: function() {
      if (this.open_type !== null && typeof (this.open_type) !== 'undefined') {
        if (this.referral !== null && typeof (this.referral) !== 'undefined') {
          this.$router.push('/referrals?rid=' + this.referral);
        } else {
          this.$router.push('/home');
        }
      } else {
        if (this.eprocess === 'rep') {
          this.$router.push('/agent-home');
        } else if (this.eprocess === 'applicant') {
          this.$router.push('/member-home');
        } else if (this.eprocess === 'group') {
          this.$router.push('/group-home');
        }
      }
    }
  },
  watch: {
    group_id: function () {
      if (this.group_id) {
        this.groupInvaild = false;
      }
    },
    platformId: function () {
      if (this.platformId) {
        this.platformError['error'] = false;
      }
    },
    subGroupId: function () {
      if(this.subGroupId) {
        localStorage.setItem('subGroupId', this.subGroupId)
      }
    },
    'portal.platform_group_id': function () {
      if (this.portal.platform_group_id) {
        this.platformError['error'] = false;
      }
    }
  }
}
</script>

<style lang="scss">
.sub-group-container {
  position: relative;
  top: -28px;
}
</style>
