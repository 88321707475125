<template>
  <section class="innerpage-wrap">
    <div class="container" ref="saveReview">

      <div class="split-title">
        <h1 class="brand-color">Review & Confirmation</h1>
      </div>
      <div class="row">
        <div class="col-xxl-8  col-xl-8 col-lg-8 col-md-12">
          <div class="row">
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6  mb-4" v-if="requiredTabs.primary_info">
              <div class="card-block review-block text-center">
                <img :src="reviewImage.personalInformation" class="img-fluid">
                <h3>Personal Info</h3>
                <a href="javscript:void(0);" class="check-btn" @click="review_pop_up('personal-info',1,'Personal Information')">View Details</a>
                <!-- <router-link to="" @click.native="editTab('personal-info')" class="check-btn">Check to Edit</router-link> -->
              </div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6  mb-4" v-if="requiredTabs.contact_info">
              <div class="card-block review-block text-center">
                <img :src="reviewImage.contactInformation" class="img-fluid">
                <h3>Contact Info</h3>
                <router-link to="" @click.native="review_pop_up('contact-info',2,'Contact Info')" class="check-btn">View Details</router-link>
              </div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6  mb-4" v-if="requiredTabs.employer_info">
              <div class="card-block review-block text-center">
                <img :src="reviewImage.employerInformation" class="img-fluid">
                <h3>Employer Info</h3>
                <router-link to="" @click.native="review_pop_up('employer-info',3,'Employer Info')" class="check-btn">View Details</router-link>
              </div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6  mb-4" v-if="requiredTabs.beneficiary_info">
              <div class="card-block review-block text-center">
                <img :src="reviewImage.beneficiaryInformation" class="img-fluid">
                <h3>Beneficiary Info</h3>
                <router-link to="" @click.native="review_pop_up('beneficiary-info',4,'Beneficiary Info')" class="check-btn">View Details</router-link>
              </div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6  mb-4" v-if="requiredTabs.primary_health_info">
              <div class="card-block review-block text-center">
                <img :src="reviewImage.healthQuestionInformation" class="img-fluid">
                <h3>Health Questions</h3>
                <router-link to="" @click.native="review_pop_up('health-questions',5,'Health Questions')" class="check-btn">View Details</router-link>
              </div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6  mb-4" v-if="requiredTabs.dependent_info">
              <div class="card-block review-block text-center">
                <img :src="reviewImage.dependentInformation" class="img-fluid">
                <h3>Dependent Info</h3>
                <router-link to="" @click.native="review_pop_up('dependent-information',6,'Dependent Info')"  class="check-btn">View Details</router-link>
              </div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6  mb-4" v-if="requiredTabs.billing_info">
              <div class="card-block review-block text-center">
                <img :src="reviewImage.billingInformation" class="img-fluid">
                <h3>Billing Info</h3>
                <router-link to="" @click.native="review_pop_up('billing-information',7,'Billing Info')"  class="check-btn">View Details</router-link>
              </div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6  mb-4" >
              <div class="card-block review-block text-center">
                <img :src="reviewImage.agreementSignatureInformation" class="img-fluid">
                <h3>Agreement & Signature</h3>
                <router-link to=""  @click.native="review_pop_up('agreement-signature',8,'Agreement & Signature')"  class="check-btn">View Details</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12">
          <div class="order-info" >
            <ul >
              <li class="split" v-for="(plan, index) in cartPlans" v-bind:key="index">
                <span>{{plan.plan_name}}</span>
                ${{plan.price}}
              </li>
              <li class="split" v-if="cc_service_charge == true">
                                <span>Credit Card service fee</span>
                                <span>${{cc_fee}}</span>
                            </li>
              <li class="split">
                <span>First Month Total Cost</span>
                <span>${{cartFirst}}</span>
              </li>
              <li class="split">
                <span>Recurring Monthly Cost</span>
                <span>${{cartReccur}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Enroll Detail Pop up -->
      <b-modal id="enrollView" centered size="lg"  v-model="show">
        <template #modal-header>
          <div class="popUp-header">
            <h4>{{pop_up_header}}</h4>

            <b-button variant="close-btn" @click="show=false">
              <i class="fas fa-times"></i>
            </b-button>
          </div>
        </template>
        <b-container>
          <div class="planPop-details reviewPopup" >
            <div class="list row" v-if="requiredTabs.primary_info &&  allReview == 1">
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="personalInfo.first_name">
                <label>First Name</label>
                <span class="info d-block">
                              {{personalInfo.first_name}}
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3"  v-if="personalInfo.middle_name">
                <label>Middle Name</label>
                <span class="info d-block">
                              {{personalInfo.middle_name}}
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="personalInfo.last_name">
                <label>Last Name</label>
                <span class="info d-block">
                              {{personalInfo.last_name}}
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="personalInfo.gender  == 0 || personalInfo.gender == 1">
                <label>Gender</label>
                <span class="info d-block" v-if="personalInfo.gender == 0">
                              Male
                            </span>
                <span class="info d-block" v-else>
                                Female
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="personalInfo.height_feet">
                <label>Height</label>
                <span class="info d-block">
                              {{personalInfo.height_feet}} feet {{personalInfo.height_inch}} Inches
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="personalInfo.weight">
                <label>Weight</label>
                <span class="info d-block">
                              {{personalInfo.weight}} Lbs
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="personalInfo.birthdate">
                <label>Date of Birth</label>
                <span class="info d-block">
                              {{personalInfo.birthdate}}
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="personalInfo.ssn">
                <label>Social security Number</label>
                <span class="info d-block">
                              {{personalInfo.ssn}}
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="personalInfo.marital_status == 0 || personalInfo.marital_status == 1 || personalInfo.marital_status == 2 || personalInfo.marital_status == 3">
                <label>Marital Status</label>
                <span class="info d-block" v-if="personalInfo.marital_status == 0">
                              Single
                            </span>
                <span class="info d-block" v-if="personalInfo.marital_status == 1">
                              Married
                            </span>
                <span class="info d-block" v-if="personalInfo.marital_status == 2">
                              Divorced
                            </span>
                <span class="info d-block" v-if="personalInfo.marital_status == 3">
                              Widowed
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="personalInfo.prior_carrier_name">
                <label>Prior Coverage Carrier Name</label>
                <span class="info d-block">
                              {{personalInfo.prior_carrier_name}}
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="personalInfo.prior_effective_date">
                <label>Prior Coverage Effective Date</label>
                <span class="info d-block">
                              {{personalInfo.prior_effective_date}}
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="personalInfo.prior_term_date">
                <label>Prior Coverage Term Date</label>
                <span class="info d-block">
                              {{personalInfo.prior_term_date}}
                            </span>
              </div>
            </div>
            <div class="list row" v-if="requiredTabs.contact_info && allReview == 2">
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="contactInfo.email_address">
                <label>Email</label>
                <span class="info d-block">
                              {{contactInfo.email_address}}
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="contactInfo.phone_number">
                <label>Contact Number</label>
                <span class="info d-block">
                              {{contactInfo.phone_number}}
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="contactInfo.address1">
                <label>Address1</label>
                <span class="info d-block">
                              {{contactInfo.address1}}
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="contactInfo.address2">
                <label>Address2</label>
                <span class="info d-block">
                              {{contactInfo.address2}}
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="contactInfo.city">
                <label>City</label>
                <span class="info d-block">
                              {{contactInfo.city}}
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="contactInfo.state">
                <label>State</label>
                <span class="info d-block">
                              {{contactInfo.state}}
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="contactInfo.zip">
                <label>Zip</label>
                <span class="info d-block">
                              {{contactInfo.zip}}
                            </span>
              </div>
             <!--  <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="contactInfo.birth_city">
                <label>Birth City</label>
                <span class="info d-block">
                              {{contactInfo.birth_city}}
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="contactInfo.birth_state">
                <label>Birth State</label>
                <span class="info d-block">
                              {{contactInfo.birth_state}}
                            </span>
              </div> -->
            </div>
            <div class="list row" v-if="requiredTabs.employer_info && allReview == 3">
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="employerInfo.name">
                <label>Name</label>
                <span class="info d-block">
                              {{employerInfo.name}}
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="employerInfo.phone">
                <label>Contact Number </label>
                <span class="info d-block">
                              {{employerInfo.phone}}
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="employerInfo.address1">
                <label>Street Address 1</label>
                <span class="info d-block">
                              {{employerInfo.address1}}
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="employerInfo.address2">
                <label>Street Address2</label>
                <span class="info d-block">
                              {{employerInfo.address2}}
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="employerInfo.city">
                <label>City-Country</label>
                <span class="info d-block">
                              {{employerInfo.city}}
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="employerInfo.state">
                <label>State</label>
                <span class="info d-block">
                              {{employerInfo.state}}
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="employerInfo.zip">
                <label>Zip/Pin Code</label>
                <span class="info d-block">
                              {{employerInfo.zip}}
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="employerInfo.average_work_week">
                <label>Average Work Week</label>
                <span class="info d-block">
                              {{employerInfo.average_work_week}}
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="employerInfo.employment_start_date">
                <label>Employment Start Date</label>
                <span class="info d-block">
                              {{employerInfo.employment_start_date}}
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="employerInfo.occupation">
                <label>Occupation</label>
                <span class="info d-block">
                              {{employerInfo.occupation}}
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="employerInfo.compensation_type">
                <label>Compensation Type</label>
                <span v-if="employerInfo.compensation_type == 'G10L20'" class="info d-block">
                              Greater than $10/hr but less than $20/hr
                            </span>
                <span v-if="employerInfo.compensation_type == 'MW'" class="info d-block">
                              Minimum wage
                            </span>
                <span v-if="employerInfo.compensation_type == 'GMWL10'" class="info d-block">
                              Greater than minimum wage but less than $10/hr
                            </span>
                <span v-if="employerInfo.compensation_type == 'G20'" class="info d-block">
                              Greater than $20/hr
                            </span>
                <span v-if="employerInfo.compensation_type == 'Salary'" class="info d-block">
                              Salary
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="employerInfo.employment_status">
                <label>Employment Status</label>
                <span class="info d-block" v-if="employerInfo.employment_status == 'A'">
                              ACTIVE
                            </span>
                <span class="info d-block" v-if="employerInfo.employment_status == 'C'">
                              COBRA
                            </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="employerInfo.emp_industry">
                <label>Employer Industry</label>
                <span class="info d-block">
                              {{employerInfo.emp_industry}}
                            </span>
              </div>
            </div>
            <div v-if="requiredTabs.beneficiary_info && allReview == 4">
              <div class="list " v-for="(beneficiary, count) in beneficiaryInfo" :key="count">
                <div class="head" @click="selected = count + 4">
                  <div class="particulars">
                    Beneficiary Information #{{++count}}
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="beneficiary.first_name">
                    <label>First Name</label>
                    <span class="info d-block">
                                      {{beneficiary.first_name}}
                                    </span>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="beneficiary.last_name">
                    <label>Last Name</label>
                    <span class="info d-block">
                                      {{beneficiary.last_name}}
                                    </span>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="beneficiary.birthdate">
                    <label>Date of Birth</label>
                    <span class="info d-block">
                                      {{beneficiary.birthdate}}
                                    </span>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="beneficiary.ssn">
                    <label>Social Security Number</label>
                    <span class="info d-block">
                                      {{beneficiary.ssn}}
                                    </span>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="beneficiary.ssn">
                    <label>Relationship</label>
                    <span class="info d-block">
                                      {{beneficiary.relationship}}
                                    </span>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="beneficiary.beneficiary_percentage">
                    <label>Beneficiary Percentage</label>
                    <span class="info d-block">
                                      {{beneficiary.beneficiary_percentage}}%
                                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="list" v-if="requiredTabs.primary_health_info && allReview == 5" >
              <div class="rc_content healthQues" :class="{open:selected == 7}">
                <ul>
                  <li v-for="(conditionQuestion, count) in primaryHealthInfo.condition_questions" :key="count">
                    <div class="split">
                      {{conditionQuestion.question_text | removeHtmlTags}}
                      <div class="text-end radioBtn">
                        <div class="form-check form-check-inline mt-2">
                          <input type="radio" :checked="conditionQuestion.yes_no_flag == 1" :disabled="true">
                          <span class="label">Yes</span>
                        </div>
                        <div class="form-check form-check-inline mt-2">
                          <input type="radio"  :checked="conditionQuestion.yes_no_flag == 0" :disabled="true">
                          <span class="label">No</span>
                        </div>
                      </div>
                    </div>

                    <div v-if="conditionQuestion.question_id == 145 || conditionQuestion.question_id == 146 || conditionQuestion.question_id == 147">
                      <div class="disease mb-3" v-if="conditionQuestion.yes_no_flag == 1 && (conditionQuestion.question_id == 146 || conditionQuestion.question_id == 147)">
                        <div >
                          <table cellspacing="0">
                            <tr>
                              <td>Condition</td>
                              <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.condition}}</label>
                              </td>
                            </tr>
                            <tr>
                              <td>Status</td>
                              <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.status}}</label>
                              </td>
                            </tr>
                            <tr>
                              <td>Last Seen By Physician</td>
                              <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.last_seen_by_physician}}</label>
                              </td>
                            </tr>
                            <tr>
                              <td>Date of Recovery</td>
                              <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.date_of_recovery}}</label>
                              </td>
                            </tr>
                            <tr>
                              <td>Physician Name</td>
                              <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.physician_name}}</label>
                              </td>
                            </tr>
                            <tr>
                              <td>Address</td>
                              <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.address}}</label>
                              </td>
                            </tr>
                            <tr>
                              <td>Phone</td>
                              <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.phone}}</label>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="disease mb-3" v-if="conditionQuestion.yes_no_flag == 1">
                        <div >
                          <table cellspacing="0">
                            <tr>
                              <td>Condition/Diseases/Diagnosis/Treatment</td>
                              <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.health_condition}}</label>
                              </td>
                            </tr>
                            <tr>
                              <td>Date of Onset</td>
                              <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.date_of_onset}}</label>
                              </td>
                            </tr>
                            <tr>
                              <td>Date of Recovery</td>
                              <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.date_of_recovery}}</label>
                              </td>
                            </tr>
                            <tr>
                              <td>Current Treatment</td>
                              <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.is_treatment == 1 ? 'Yes' : 'No'}}</label>
                              </td>
                            </tr>
                            <tr>
                              <td>Taking Medication</td>
                              <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.is_medicate == 1 ? 'Yes' : 'No'}}</label>
                              </td>

                            </tr>
                            <tr>
                              <td>Date Last Seen By Physician</td>
                              <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.d_last_seen}}</label>
                              </td>

                            </tr>
                            <tr>
                              <td>Remaining Symptoms of Problems? Surgery or Hospitalization</td>
                              <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.symptoms}}</label>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <!-- <div v-for="(conditionQuestion, count) in primaryHealthInfo.condition_questions" :key="count">
                  <div class="quest">
                      <div class="q_container">
                          <span class="questions">{{conditionQuestion.question_text | removeHtmlTags}}</span>
                          <span class="answer">
                            <input type="radio" :checked="conditionQuestion.yes_no_flag == 1" :disabled="true">
                            <span class="label">Yes</span>
                            <input type="radio"  :checked="conditionQuestion.yes_no_flag == 0" :disabled="true">
                            <span class="label">No</span>
                          </span>
                      </div>
                  </div>
                  <div v-if="conditionQuestion.question_id == 146 || conditionQuestion.question_id == 147">
                    <div class="disease" v-if="conditionQuestion.yes_no_flag == 1">
                        <div >
                        <table cellspacing="0">
                            <tr>
                                <td>Condition</td>
                                <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                    <label>{{condition.condition}}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>Status</td>
                                <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                    <label>{{condition.status}}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>Last Seen By Physician</td>
                                <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.last_seen_by_physician}}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>Date of Recovery</td>
                                <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.date_of_recovery}}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>Physician Name</td>
                                <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.physician_name}}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>Address</td>
                                <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.address}}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>Phone</td>
                                <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.phone}}</label>
                                </td>
                            </tr>
                        </table>
                        </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="disease" v-if="conditionQuestion.yes_no_flag == 1">
                      <div >
                        <table cellspacing="0">
                            <tr>
                                <td>Condition/Diseases/Diagnosis/Treatment</td>
                                <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                    <label>{{condition.health_condition}}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>Date of Onset</td>
                                <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                    <label>{{condition.date_of_onset}}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>Date of Recovery</td>
                                <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.date_of_recovery}}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>Current Treatment</td>
                                <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                  <label>{{condition.is_treatment == 1 ? 'Yes' : 'No'}}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>Taking Medication</td>
                                <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                    <label>{{condition.is_medicate == 1 ? 'Yes' : 'No'}}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>Date Last Seen By Physician</td>
                                <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                    <label>{{condition.d_last_seen}}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>Remaining Symptoms of Problems? Surgery or Hospitalization</td>
                                <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                    <label>{{condition.symptoms}}</label>
                                </td>
                            </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div> -->
                <div class="quest" v-if="primaryHealthInfo.medication_question && primaryHealthInfo.medication_question.medications.length > 0">
                  <div class="q_container">
                    <span class="questions">{{primaryHealthInfo.medication_question.question_text}}</span>
                    <span class="answer">
                                        <input type="radio" :checked="primaryHealthInfo.medication_question.yes_no_flag == 1" :disabled="true">
                                        <span class="label">Yes</span>
                                        <input type="radio"  :checked="primaryHealthInfo.medication_question.yes_no_flag == 0" :disabled="true">
                                        <span class="label">No</span>
                                      </span>
                  </div>
                </div>
                <div class="disease mb-3" v-if="primaryHealthInfo.medication_question.yes_no_flag == 1">
                  <div >
                    <table cellspacing="0">
                      <tr>
                        <td>Medication/Rx injection</td>
                        <td v-for="(medication, count) in primaryHealthInfo.medication_question.medications" :key="count">
                          <label>{{medication.medication}}</label>
                        </td>
                      </tr>
                      <tr>
                        <td>Dosage</td>
                        <td v-for="(medication, count) in primaryHealthInfo.medication_question.medications" :key="count">
                          <label>{{medication.dosage}}</label>
                        </td>
                      </tr>
                      <tr>
                        <td>Medical Condition</td>
                        <td v-for="(medication, count) in primaryHealthInfo.medication_question.medications" :key="count">
                          <label>{{medication.medical_condition}}</label>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="additional_notes">
                  <p>Additional Notes</p>
                  <textarea class="notes custom-input mb-3" v-model="primaryHealthInfo.notes" :readonly="true" rows="5" cols="50"></textarea>
                </div>
              </div>
            </div>
            <div v-if="requiredTabs.dependent_info && allReview == 6">
              <div v-for="(dependents, count) in dependentInfo" :key="count">
                <div class="list">
                  <div class="head" @click="selected = count + 8">
                    <div class="particulars">
                      Dependent #{{count + 1}} - Primary Information
                    </div>
                  </div>
                  <div class="rc_content row" :class="{open:selected == count + 8}">
                    <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="dependents.primary_info.first_name">
                      <label>First Name</label>
                      <span class="info d-block">
                                    {{dependents.primary_info.first_name}}
                                  </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="dependents.primary_info.last_name">
                      <label>Last Name</label>
                      <span class="info d-block">
                                    {{dependents.primary_info.last_name}}
                                  </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="dependents.primary_info.height_feet">
                      <label>Height</label>
                      <span class="info d-block">
                                    {{dependents.primary_info.height_feet}} feet {{dependents.primary_info.height_inch}} Inches
                                  </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="dependents.primary_info.weight">
                      <label>Weight</label>
                      <span class="info d-block">
                                    {{dependents.primary_info.weight}} Lbs
                                  </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="dependents.primary_info.gender  == 0 || dependents.primary_info.gender == 1">
                      <label>Gender</label>
                      <span class="info d-block" v-if="dependents.primary_info.gender == 0">
                                    Male
                                  </span>
                      <span class="info d-block" v-else>
                                      Female
                                  </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="dependents.primary_info.birthdate">
                      <label>Date of Birth</label>
                      <span class="info d-block">
                                    {{dependents.primary_info.birthdate}}
                                  </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="dependents.primary_info.ssn">
                      <label>Social Security Number</label>
                      <span class="info d-block">
                                    {{dependents.primary_info.ssn}}
                                  </span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="dependents.primary_info.relationship">
                      <label>Relationship</label>
                      <span class="info d-block">
                                    {{dependents.primary_info.relationship}}
                                  </span>
                    </div>
                  </div>
                </div>

                <div class="list" v-if="requiredTabs.primary_health_info ">
                  <div class="head" @click="selected = count + 15">
                    <div class="particulars">
                      Dependent #{{count + 1}} - Health Information
                    </div>
                  </div>
                  <div class="rc_content" :class="{open:selected == count + 15}">
                    <div v-for="(conditionQuestion, count) in dependents.health_info.condition_questions" :key="count">
                      <div class="quest">
                        <div class="q_container">
                          <span class="questions">{{conditionQuestion.question_text}}</span>
                          <span class="answer">
                                            <input type="radio" :checked="conditionQuestion.yes_no_flag == 1" :disabled="true">
                                            <span class="label">Yes</span>
                                            <input type="radio"  :checked="conditionQuestion.yes_no_flag == 0" :disabled="true">
                                            <span class="label">No</span>
                                          </span>
                        </div>
                      </div>
                      <div class="disease" v-if="conditionQuestion.yes_no_flag == 1">
                        <div >
                          <table cellspacing="0">
                            <tr>
                              <td>Condition/Diseases/Diagnosis/Treatment</td>
                              <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.health_condition}}</label>
                              </td>
                            </tr>
                            <tr>
                              <td>Date of Onset</td>
                              <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.date_of_onset}}</label>
                              </td>
                            </tr>
                            <tr>
                              <td>Date of Recovery</td>
                              <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.date_of_recovery}}</label>
                              </td>
                            </tr>
                            <tr>
                              <td>Current Treatment</td>
                              <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.is_treatment == 1 ? 'Yes' : 'No'}}</label>
                              </td>
                            </tr>
                            <tr>
                              <td>Taking Medication</td>
                              <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.is_medicate == 1 ? 'Yes' : 'No'}}</label>
                              </td>

                            </tr>
                            <tr>
                              <td>Date Last Seen By Physician</td>
                              <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.d_last_seen}}</label>
                              </td>

                            </tr>
                            <tr>
                              <td>Remaining Symptoms of Problems? Surgery or Hospitalization</td>
                              <td v-for="(condition, count) in conditionQuestion.conditions" :key="count">
                                <label>{{condition.symptoms}}</label>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="quest" v-if="dependents.health_info.medication_question">
                      <div class="q_container">
                        <span class="questions">{{dependents.health_info.medication_question.question_text}}</span>
                        <span class="answer">
                                            <input type="radio" :checked="dependents.health_info.medication_question.yes_no_flag == 1" :disabled="true">
                                            <span class="label">Yes</span>
                                            <input type="radio"  :checked="dependents.health_info.medication_question.yes_no_flag == 0" :disabled="true">
                                            <span class="label">No</span>
                                          </span>
                      </div>
                    </div>
                    <div class="disease" v-if="dependents.health_info.medication_question.yes_no_flag == 1">
                      <div >
                        <table cellspacing="0">
                          <tr>
                            <td>Medication/Rx injection</td>
                            <td v-for="(medication, count) in dependents.health_info.medication_question.medications" :key="count">
                              <label>{{medication.medication}}</label>
                            </td>
                          </tr>
                          <tr>
                            <td>Dosage</td>
                            <td v-for="(medication, count) in dependents.health_info.medication_question.medications" :key="count">
                              <label>{{medication.dosage}}</label>
                            </td>
                          </tr>
                          <tr>
                            <td>Medical Condition</td>
                            <td v-for="(medication, count) in dependents.health_info.medication_question.medications" :key="count">
                              <label>{{medication.medical_condition}}</label>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div class="additional_notes">
                      <p>Additional Notes</p>
                      <textarea class="notes custom-input mb-3" v-model="dependents.health_info.notes" :readonly="true" rows="5" cols="50"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="list" v-if="requiredTabs.billing_info && allReview == 7">
              <div class="rc_content row" :class="{open:selected == 25}">
                <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="billingInfo.payment_method">
                  <label>Payment Method</label>
                  <span class="info d-block">
                                  {{billingInfo.payment_method.toUpperCase()}}
                                </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="billingInfo.first_name_on_cc">
                  <label>First Name on CC</label>
                  <span class="info d-block">
                                  {{billingInfo.first_name_on_cc}}
                                </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="billingInfo.last_name_on_cc">
                  <label>Last Name on CC</label>
                  <span class="info d-block">
                                  {{billingInfo.last_name_on_cc}}
                                </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="billingInfo.card_number">
                  <label>Card Number</label>
                  <span class="info d-block">
                                  {{billingInfo.card_number}}
                                </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="billingInfo.expiration_year">
                  <label>Expiration Year</label>
                  <span class="info d-block">
                                  {{billingInfo.expiration_year}}
                                </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="billingInfo.expiration_month">
                  <label>Expiration Month</label>
                  <span class="info d-block">
                                  {{billingInfo.expiration_month}}
                                </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="billingInfo.address1">
                  <label>Street Address</label>
                  <span class="info d-block">
                                  {{billingInfo.address1}}
                                </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="billingInfo.city">
                  <label>City</label>
                  <span class="info d-block">
                                  {{billingInfo.city}}
                                </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="billingInfo.state">
                  <label>State</label>
                  <span class="info d-block">
                                  {{billingInfo.state}}
                                </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="billingInfo.zip">
                  <label>Zip</label>
                  <span class="info d-block">
                                  {{billingInfo.zip}}
                                </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="billingInfo.account_name">
                  <label>Account Name</label>
                  <span class="info d-block">
                                  {{billingInfo.account_name}}
                                </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="billingInfo.account_number">
                  <label>Account Number</label>
                  <span class="info d-block">
                                  {{billingInfo.account_number}}
                                </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="billingInfo.routing_number">
                  <label>Routing Number</label>
                  <span class="info d-block">
                                  {{billingInfo.routing_number}}
                                </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="billingInfo.bank_name">
                  <label>Bank Name</label>
                  <span class="info d-block">
                                  {{billingInfo.bank_name}}
                                </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="billingInfo.account_type">
                  <label>Account Type</label>
                  <span class="info d-block">
                                  {{billingInfo.account_type.toUpperCase()}}
                                </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 mb-3" v-if="billingInfo.account_holder_type">
                  <label>Account Holder Type</label>
                  <span class="info d-block">
                                  {{billingInfo.account_holder_type.toUpperCase()}}
                                </span>
                </div>
              </div>
            </div>
            <div class="list" v-if="requiredTabs.agreement_info && allReview == 8">
              <div class="notes mb-4" style="border:1px solid; padding:5px;" v-for="(agreementText, index) in agreementTexts" :key="agreementText.text_body">
                <div style="padding:2px 5px;">
                  <div v-html="agreementText.text_head">
                    <span>{{index}}</span>
                  </div>
                  <div v-html="agreementText.text_body">
                  </div>
                </div>
              </div>
            </div>
            <div class="action-btn">
              <router-link to="" @click.native="editTab(view_tab)" class="button-brand" style="  width: 100%;   display: block;   text-align: center;">Edit Details</router-link>
            </div>

          </div>
        </b-container>
      </b-modal>

      <b-modal v-bind:hide-footer="true" id="code-send" centered size="md" >
        <template #modal-header>
          <div class="popUp-header" style="border-bottom: 0px !important">
            <div  v-if="saveSuccess">
              <h4 style="color: green"><b>Information Saved!!!</b> Check your email to continue</h4>
            </div>
            <div v-else>
              <h4>Please enter the alternate email you want to send Enrollment code to?</h4>
            </div>
            <b-button variant="close-btn" @click="$bvModal.hide('code-send')">
              <i class="fas fa-times"></i>
            </b-button>
          </div>
        </template>
        <b-container fluid>
          <div v-if="saveSuccess==false" class="input_box client_email">
            <input type="text"
                   class="custom-input"
                   :class="{'form-control is-invalid': email && !isEmailValid || isEmailNull}"
                   @input="emailValid(); removeError();"
                   v-model="email"
                   value="" />
            <div class="form-group__message  ml-3 mt-2" v-if="email && !isEmailValid || isEmailNull">
              Enter valid email address
            </div>
            <div class="error_message" v-if="formErrorFlag == true">{{formErrors['email_message']}}</div>
          </div>
          <div class="popUp-details text-center">
            <div v-if="saveSuccess" class="action-btn text-center mt-4">
              <b-button style="width:100%;"  class="button-brand mx-1" @click="$bvModal.hide('code-send')" data-dismiss="modal">Done</b-button>
            </div>
            <div v-else class="action-btn text-center mt-4">
              <b-button style="width:40%;"  class="dark-btn mx-1" @click="$bvModal.hide('code-send')">Back</b-button>
              <b-button style="width:40%;"  class="button-brand mx-1" @click="saveAndContinueEnrollment" data-dismiss="modal">Send Code</b-button>
            </div>
          </div>
        </b-container>

      </b-modal>
      <div>
        <b-modal v-bind:hide-footer="true" centered size="md" id="error-message">
          <template #modal-header>
            <div class="popUp-header">
              <b-button variant="close-btn" @click="$bvModal.hide('error-message')">
                <i class="fas fa-times"></i>
              </b-button>
            </div>
            <b-container fluid>
              <div class="planPop-details text-center">
                <div class="content">
                  <p>Something went wrong. Please save your enrollment.</p>
                </div>
              </div>
            </b-container>
          </template>
          <div class="modal-footer" style="border-top: 0px !important">
            <b-button variant="primary" @click.native="saveEnrollment">Save and Continue Later</b-button>
          </div>
        </b-modal>
      </div>
      <div style="color: red;padding: 1em;" v-if="mainErrorFlag == true">{{mainErrors}}</div>
      <div class="action-btn split-title mt-5">
        <router-link to="" @click.native="navigatePrevious" class="dark-btn">Back</router-link>
        <div class="action-next">
          <router-link to="" @click.native="saveEnrollment" class="button-green">Save & Continue Later</router-link>
          <router-link to="" @click.native="saveData" :disabled="validated" class="button-brand">Next</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import html2canvas from 'html2canvas';
import {checkEmail} from "../../utils/validators";
export default {
  name: "ReviewAndConfirm",
  data: function () {
    return {
      show: false,
      tempId: '',
      requestUrl: process.env.VUE_APP_API_BASE+'/get-enrollment-temp-info',
      requiredTabs: {
        primary_info: false,
        contact_info: false,
        employer_info: false,
        beneficiary_info: false,
        primary_health_info: false,
        dependent_info: false,
        review_info: false,
        agreement_info: false,
        billing_info: false
      },
      effectiveDate: 'loading...',
      cartPlans: [],
      cartFirst: '',
      cartReccur: '',
      personalInfo: [],
      contactInfo: [],
      employerInfo: [],
      beneficiaryInfo: [],
      primaryHealthInfo: [],
      dependentInfo: [],
      billingInfo: [],
      additional_notes: '',
      open:false,
      selected: undefined,
      validated: false,
      validateEnrollment: false,
      savePopup: false,
      errorPopup: false,
      email: '',
      saveSuccess: false,
      formErrors: [],
      formErrorFlag: false,
      mainErrorFlag : false,
      mainErrors : '',
      loader:false,
      allReview:'',
      view_tab:'',
      pop_up_header:'',
      agreementTexts:[],
      isEmailNull: false,
      isEmailValid: false,
      cc_fee: '',
      cc_service_charge:false,
      reviewImage: {
        personalInformation: require(process.env.VUE_APP_PERSONAL_INFORMATION_REVIEW),
        contactInformation: require(process.env.VUE_APP_CONTACT_INFORMATION_REVIEW),
        employerInformation: require(process.env.VUE_APP_EMPLOYER_INFORMATION_REVIEW),
        beneficiaryInformation: require(process.env.VUE_APP_BENEFICIARY_INFORMATION_REVIEW),
        healthQuestionInformation: require(process.env.VUE_APP_HEALTH_QUESTIONS_REVIEW),
        dependentInformation: require(process.env.VUE_APP_DEPENDENT_INFORMATION_REVIEW),
        billingInformation: require(process.env.VUE_APP_BILLING_INFORMATION_REVIEW),
        agreementSignatureInformation: require(process.env.VUE_APP_AGREEMENT_SIGNATURE_REVIEW)
      }
    }
  },
  mounted() {
    let app = this;
    app.tempId = window.localStorage.getItem('tempId');
    app.cc_service_charge =  window.localStorage.getItem("charge");
    app.scrollTop();
    app.getRequiredTabs();
    app.getInfo('effective_date');
    app.getInfo('primary_info');
    app.getInfo('contact_info');
    app.getInfo('employer_info');
    app.getInfo('benificiary_info');
    app.getInfo('health_info');
    app.getInfo('dependent_info');
    app.getInfo('payment_info');
    app.getInfo('review_info');
    app.getProductInfo();
    app.getAgreementTexts();

  },
  methods: {
    getRequiredTabs: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-dynamic-tabs', {
        params: {
          enrollment_id: app.tempId,
        }
      })
          .then(function (response) {
            console.log(response.data.data);
            if (response.data.status == 'success') {
              response.data.data.result.forEach(function (item) {
                app.requiredTabs[item.tab_name] = item.required;
              });
              console.log(app.requiredTabs);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    review_pop_up: function($name,$number,$header){
      let app = this;
      app.allReview=$number;
      app.view_tab=$name;
      app.pop_up_header=$header;
      app.$bvModal.show('enrollView');
    },
    getInfo: function (column) {
      let app = this;
      axios.get(app.requestUrl, {
        params: {
          enrollment_id: app.tempId,
          column: column
        }
      })
          .then(function (response) {
            console.log(response.data.data);
            if (response.data.status == 'success') {
              if (column == 'effective_date') {
                app.effectiveDate = response.data.data.result;
              } else if (column == 'primary_info') {
                app.personalInfo = response.data.data.result;
              }  else if (column == 'contact_info') {
                app.contactInfo = response.data.data.result;
              } else if (column == 'employer_info') {
                app.employerInfo = response.data.data.result;
              } else if (column == 'benificiary_info') {
                app.beneficiaryInfo = response.data.data.result;
              } else if (column == 'health_info') {
                app.primaryHealthInfo = response.data.data.result;
              } else if (column == 'dependent_info') {
                app.dependentInfo = response.data.data.result;
              } else if (column == 'payment_info') {
                app.billingInfo = response.data.data.result;
                if(app.billingInfo.payment_method == 'cc'){
                       app.cc_service_charge = true;
                  }
                  else{
                      app.cc_service_charge = false;
                  }
                      window.localStorage.setItem('charge',app.cc_service_charge);
              } else if (column == 'review_info') {
                app.additional_notes = response.data.data.result.additional_notes;
              }
            }

          })

          .catch(function (error) {
            console.log(error);
          });

    },
    getAgreementTexts: function () {
      let app = this;
      let url = process.env.VUE_APP_API_BASE+'/get-agreement-texts';
      axios.get(url, {
        params: {
          enrollment_id: app.tempId,
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.mapToAgreeementText(response.data.data.result);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    mapToAgreeementText (results) {
      results.forEach( params => {
        let agreementObj = {
          'text_head': params.text_head,
          'text_body': params.text_body,
          'text_required': params.text_required,
          'agreement': null,
          'agreementErr': false
        }
        this.agreementTexts.push(agreementObj)
      })
    },
    getProductInfo: function () {
                let app = this;
      app.cc_fee = 0;
      app.cartGrossAmount = 0;
      axios
        .get(process.env.VUE_APP_API_BASE + "/get-plan-cart", {
          params: {
            enrollment_id: app.tempId,
          },
        })
        .then(function (response) {
          console.log(response.data);
          if (response.data.status == "success") {
            response.data.data.result.forEach(function (item) {
              if(item.removable == true){

                item.price= item.price.replace(/,/g, ``)
                app.cartGrossAmount = parseFloat(item.price)  + app.cartGrossAmount;
                }
              app.cartPlans.push(item);
            });
            if(app.cc_service_charge == true){
                  app.cc_fee = parseFloat(3.5/100*app.cartGrossAmount).toFixed(2);
            }
            app.cartFirst = parseFloat(response.data.data.initial_month_cost.replace(/,/g, ``))  + parseFloat(app.cc_fee);
            app.cartFirst = app.cartFirst.toFixed(2);
            app.cartReccur = parseFloat(response.data.data.recurring_month_cost.replace(/,/g, ``))  + parseFloat(app.cc_fee);
            app.cartReccur = app.cartReccur.toFixed(2);
            app.cartTotal = parseFloat(response.data.data.initial_month_cost.replace(/,/g, ``))  + parseFloat(app.cc_fee);
            app.cartTotal = app.cartTotal.toFixed(2)

          }
        })
        .catch(function (error) {
          console.log(error);
        });
            },
    saveData: async function () {
      let app = this;
      app.validateEnrollment = true;
      app.loader = true;
      if(app.dependentInfo == null){
        app.removeDependent();
      }
      localStorage.removeItem('cartCount')
      /* validatin dependent at the last of enrollment as well starts */
      /*app.mainErrorFlag = false
      await Promise.all(this.dependentInfo.map(async (key,data) => {
            await app.validateExistingDependentData(data,key);
      }))
    if(app.mainErrorFlag) return; */
      /* validatin dependent at the last of enrollment as well starts */
      const el = this.$refs.saveReview;
      (async () => {
        this.saveScreenShot((await html2canvas(el)).toDataURL('image/png'), 'review_info');
      })();
      let url = process.env.VUE_APP_API_BASE+'/save-review-info';
      let params = {
        enrollment_id: app.tempId,
        additional_notes: app.additional_notes,
        first_enroll: 1,
      };
      axios.post(url, params)
          .then(function (response) {
            console.log(response);
            if (response.data.status == 'success') {
              console.log("success");
              app.validated = true;
              app.saveAndContinue();
              // app.loader = false
            }else{
              app.validated = false;
            }
          })
          .catch(function (error) {
            app.validated = true;
            console.log(error.response.data.data);
            if (error.response.data.status == 'error') {
              app.loader = false
              app.$bvModal.show('error-message');
              console.log('error');
            }
          });
    },
    removeDependent: function(){
      let app = this;
      let url = process.env.VUE_APP_API_BASE+'/delete-dependent-in-IO';
      let params = {
        enrollment_id: app.tempId,
      };
      axios.post(url, params)
          .then(function (response) {
            if (response.data.status == 'success') {
              app.message = response.data.message;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    editTab: function (tab) {
      console.log("here"+tab);
      window.localStorage.setItem('navto', 'review');
      this.$router.push(tab);
    },
    saveAndContinue() {
      console.log('******* inside saveAndContinue ******')
      //Update APIs
      //Navigate to next page
      // this.$router.push({name: 'receipt'});
      if (localStorage.getItem("navto") === null || localStorage.getItem("navto") === undefined || localStorage.getItem("navto") === '') {
        //...
      }else{
        window.localStorage.removeItem('navto');
      }
      this.$router.push('receipt');
      localStorage.removeItem('cartCount')
      this.$root.$emit('cartCountStatus', 'cardStatus');
    },
    navigatePrevious() {
      //Update APIs
      //Navigate to next page
      this.$emit('comEnvNavigationBack', this.$route.name);
    },
    saveEnrollment : function () {
      let app = this;
      app.$bvModal.show('code-send');
      app.$bvModal.hide('error-message');
    },
    closePopup : function () {
      let app = this;
      app.savePopup = false;
      app.errorPopup = false;
    },
    saveAndContinueEnrollment : function () {
      if (!this.isEmailValid) {
        this.isEmailNull = true;
      } else {
        let app = this;
        let params = {
          additional_notes: app.additional_notes
        }
        let data = {
          enrollment_id: app.tempId,
          email: app.email,
          tab: 'review_info',
          data: params
        }
        app.formErrorFlag = false;
        app.formErrors = [];
        axios.post(process.env.VUE_APP_API_BASE + '/save-enrollment', data)
            .then(function (response) {
              if (response.data.status == 'success') {
                app.saveSuccess = true;
                console.log("success");
              }
            })
            .catch(function (error) {
              if (error.response.data.status == 'error') {
                console.log('error');
                error.response.data.data.forEach(function (item) {
                  app.formErrorFlag = true;
                  app.formErrors[item.target_element + '_message'] = item.error_message;
                });
                console.log(app.formErrors);
              }
            });
      }
    },
    saveScreenShot (image, name) {
      console.log(image)
      console.log(name)
      let app = this;
      let data = {
        "enrollment_id" : app.tempId,
        "name" : name,
        "image" : image
      };
      let config = {
        method: 'post',
        url: process.env.VUE_APP_API_BASE+'/save-screenshot',
        headers: { },
        data : data
      };
      axios(config)
          .then(function (response) {
            if (response.data.status == 'success') {
              console.log('screenshot saved');
            }
          })
          .catch(function (error) {
            console.log('screenshot not saved');
            console.log(error);
          });
    },
    scrollTop(){
      window.scrollTo(0,0);
    },
    validateExistingDependentData(key,data) {
      let app = this;
      if(app.mainErrorFlag == true) return;
      for (const key of Object.keys(data.primary_info)) {
        if (data.primary_info[key] === null || data.primary_info[key] === "") {
          delete data.primary_info[key];
        }
      }
      data.enrollment_id = this.tempId
      return axios.post(process.env.VUE_APP_API_BASE+'/validate-single-dependent', data)
          .then(function () {
            //app.mainErrorFlag = false;
          })
          .catch(function (error) {
            if (error.response.data.status == 'error') {
              error.response.data.data.forEach(function(item) {
                app.mainErrorFlag = true;
                app.mainErrors = "Dependent #"+(key+1)+' '+item.error_message;
              });
            }
          });
    },
    emailValid () {
      this.isEmailValid = checkEmail(this.email)
      this.isEmailNull = !checkEmail(this.email)
    }
  }
}
</script>

<style scoped>
.informations .entrollment_review .list:last-child .particulars {
  width: 100%;
}
.q_container{
  display: flex;
  justify-content: space-between;
  padding-bottom: 7px;
}
.q_container .answer {
  min-width: 95px;
}
.q_container .label {
  margin: 0 6px;
}
</style>
