<template>
    <header>
        <div class="top-header">
            <div class="container">
                <div class="row">
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 mobile-hide">
                      <ul>
                        <li><a><i class="fas fa-envelope"></i> {{ email }}</a></li>
                        <li><a><i class="fas fa-phone-alt"></i> {{ phone }}</a></li>
                      </ul>
                    </div>
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                      <p v-html="tagline"></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-header">
            <div class="container">
                <div class="row">
                  <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-6 col-6">
                    <div class="store-logo" @click="homePage">
                      <img :src="logo" alt="Image" class="img-fluid">
                    </div>
                  </div>
                  <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-6 col-6 left-border  mobile-hide">
                    <div class="agent-logo">
                      <img :src="image" alt="Image" class="img-fluid">
                    </div>
                  </div>
                    <div class="col-xxl-8 col-xl-8 col-lg-6 col-md-6 col-6 vertical-center">
                        <div class="cart-btn">
                            <router-link to="/cart" v-b-tooltip.hover title="View Cart">
                                <img :src="cartLogo" alt="">
                            </router-link>
                            <span class="badge">{{ cartItems }}</span>
                        </div>
                        <div class="about-btn mobile-hide">
                            <router-link to="" v-b-modal.aboutUser>About {{ display_name }}</router-link>
                        </div>

                       <!-- For rep and group registration clickable link start-->
                        <div class="registration-container mobile-hide">
                          <Bubble right :closeOnNavigation="true">
                            <img :src="logo" alt="" class="img-fluid">
                            <div class="agent-mobile-detail text-center">
                              <ul>
                                <li><a href="javascript:void(0);"><i class="fas fa-envelope"></i> {{ email }}</a></li>
                                <li><a href="javascript:void(0);"><i class="fas fa-phone-alt"></i> {{ phone }}</a></li>
                                <li><a href="javascript:void(0);" v-b-modal.aboutUser>{{ display_name }}</a></li>
                              </ul>
                            </div><hr>

                            <ul class="menu-list">
                              <li v-for="(registration,index) in registrationArray" v-bind:key="index">
                                <a href="javascript:void(0)" class="text-uppercase" @click="routeToLink(registration.link)">{{ registration.name }}</a>
                              </li>
                            </ul>
                          </Bubble>
                        </div>
                        <!-- For rep and group registration clickable link end-->

                        <Bubble right class="mobile-display" :closeOnNavigation="true">
                            <img :src="logo" alt="" class="img-fluid">
                            <div class="agent-mobile-detail text-center">
                                <ul>
                                    <li><a href="javascript:void(0);"><i class="fas fa-envelope"></i> {{ email }}</a></li>
                                    <li><a href="javascript:void(0);"><i class="fas fa-phone-alt"></i> {{ phone }}</a></li>
                                    <li><a href="javascript:void(0);" v-b-modal.aboutUser>{{ display_name }}</a></li>
                                </ul>
                            </div><hr>
                           <!-- <div v-for="(menu,index) in menuArray" v-bind:key="index">
                              <a href="javascript:void(0)">
                                  <span @click="menuItem(menu)">{{ menu }}</span>
                              </a>
                            </div> -->
                          <div>
                          <ul class="menu-list"  v-if="portal_id">
                            <li v-for="(menu,index) in menuArray" v-bind:key="index">
                              <a href="javascript:void(0)" class="text-uppercase" @click="menuItem(menu)">{{ menu }}</a>
                            </li>
                          </ul>
                          </div><hr>
                          <div>
                            <ul class="menu-list">
                              <li v-for="(registration,index) in registrationArray" v-bind:key="index">
                                <a href="javascript:void(0)" class="text-uppercase" @click="routeToLink(registration.link)">{{ registration.name }}</a>
                              </li>
                            </ul>
                          </div>
                        </Bubble>
                    </div>
                </div>
            </div>
        </div>
        <div class="nav-menu mobile-hide" v-if="portal_id">
            <div class="container">
                <ul>
<!--                  <li v-for="(menu,index) in menus" v-bind:key="index">
                    <a href="javascript:void(0)" @click="menuItem(menu)">{{ menu }}</a>
                  </li>-->
                  <li v-for="(menu,index) in menuArray" v-bind:key="index">
                    <a href="javascript:void(0)"
                       class="menu"
                       :class="{'active-header': menu === menuSelectedItem}"
                       @click="menuItem(menu)">{{ menu }}</a>
                  </li>
                </ul>
            </div>
        </div>

      <div class="vertical-line" v-if="!portal_id"> </div>
        <!-- User About Pop up -->
        <b-modal id="aboutUser" centered size="md"  v-model="show">
            <template #modal-header>
                <div class="popUp-header">
                    <h4>About {{ display_name }}</h4>

                    <b-button variant="close-btn" @click="show=false">
                        <i class="fas fa-times"></i>
                    </b-button>
                </div>
            </template>
            <b-container fluid>
                <div class="aboutUser-details">
                  <p v-html="about_content"></p>
                </div>
            </b-container>
        </b-modal>
    </header>
</template>
<script>
import { Bubble } from 'vue-burger-menu'
import axios from "axios";
export default {
    name: 'SiteHeader',
    components: {
        Bubble
    },
    data() {
        return {
          show: false,
          logo: require(process.env.VUE_APP_DEFAULT_LOGO),
          image: require(process.env.VUE_APP_DEFAULT_LOGO),
          cartLogo: require(process.env.VUE_APP_CART_LOGO),
          phone: 'Loading..',
          email: 'Loading..',
          tagline: '',
          about_name: '...',
          display_name: '...',
          cartItems: 0,
          about_popup: false,
          about_content: 'Not Available',
          eprocess: '',
          open_type: '',
          group_id: '',
          agent_id: '',
          menuList: [],
          /* menus: {
            dental: '',
            vision: '',
            medical: '',
            limitedmed: '',
            term_life: '',
            supplemental: '',
            lifestyle: '',
            rx: '',
            di: '',
            critical: '',
            accident: '',
            hospital: ''
          },*/
          portal_id: '',
          menus: {},
          menuName: '',
          menuArray:[],
          isActive: false,
          menuSelectedItem: '',
          registrationArray: [
            {
              name: 'Rep Registration',
              link: process.env.VUE_APP_REP_REGISTRATION
            },
            {
              name: 'Rep Dashboard',
              link: process.env.VUE_APP_REP_DASHBOARD
            },
            {
              name: 'Group Registration',
              link:  process.env.VUE_APP_GROUP_REGISTRATION
            },
            {
              name: 'Group Dashboard',
              link: process.env.VUE_APP_GROUP_DASHBOARD
            },
            {
              name: 'Member Registration',
              link: process.env.VUE_APP_MEMBER_REGISTRATION
            },
            {
              name: 'Member Dashboard',
              link: process.env.VUE_APP_MEMBER_DASHBOARD
            }
          ]
        }
    },
  mounted() {
    let app = this;
    app.group_id = window.localStorage.getItem('group_id');
    app.agent_id = window.localStorage.getItem('agent_id');
    app.open_type = window.localStorage.getItem('open_type');
    app.eprocess = window.localStorage.getItem('eprocess');
    app.portal_id = window.localStorage.getItem('portal_id');
    app.referral = window.localStorage.getItem('referral');
    app.cartItems = localStorage.getItem('cartCount') ? localStorage.getItem('cartCount') : app.cartItems;
    localStorage.getItem('menuSelectedItem') ? app.menuSelectedItem = localStorage.getItem('menuSelectedItem'): '';
    console.log('eprocess of landing page',app.eprocess);
    app.getMenus();
    if (app.open_type === 'group' || app.eprocess === 'group') {
      app.getGroupHeaderInfo();
      app.getGroupAboutInfo();
    } else {
      //open landing pages
      app.getAgentHeaderInfo();
      app.getAgentAboutInfo();
    }
    app.$root.$on('cartData', (id) => {
      app.cartCount(id);
    });
    app.$root.$on('cartCountStatus', (id) => {
      console.log('***** inside cart count status*****')
      console.log(id)
      app.cartItems = localStorage.getItem('cartCount') ?  localStorage.getItem('cartCount') : 0
    });
    this.$root.$on('menuSelected', (menu) => {
      console.log(menu)
      app.menuSelectedItem = ''
    });
  },
  methods: {
      homePage: function() {
        if (this.open_type !== null && typeof(this.open_type) !== 'undefined') {
          if (this.referral !== null && typeof(this.referral) !== 'undefined') {
            this.$router.push('/referrals?rid='+this.referral);
          } else {
            this.$router.push('/');
          }
        } else {
          if (this.eprocess === 'rep') {
            this.$router.push('/agent-home');
          }else if (this.eprocess === 'applicant') {
            this.$router.push('/member-home');
          } else if (this.eprocess === 'group') {
            this.$router.push('/group-home');
          }
        }
      },
      getMenus: function() {
        console.log('******* getMenus *********')
      let app = this;
      let url;
      // delete app.menus.di;
      let portal = window.localStorage.getItem('portal_id');
      app.group_id = window.localStorage.getItem('group_id');
      // let group = window.localStorage.getItem('group_id');
      if(app.eprocess === 'rep')
      {
        if (portal !== null) {
          url = process.env.VUE_APP_API_BASE+`/get-common-menu?agent_id=${app.agent_id}&group_id=${app.group_id}&portal_id=${portal}&eprocess=${app.eprocess}&open_type=${app.open_type}`;
        } else {
          url = process.env.VUE_APP_API_BASE+`/get-common-menu?agent_id=${app.agent_id}&group_id=${app.group_id}&eprocess=${app.eprocess}&open_type=${app.open_type}`;
        }
         // url = process.env.VUE_APP_API_BASE+`/get-agent-menu?agent_id=${app.agent_id}`;
      } else {
        let fromSite = window.localStorage.getItem('fromSite')
        if ( fromSite == 'elevate') {
          url = process.env.VUE_APP_API_BASE+`/get-group-menu?group_id=${app.group_id}&from_site=${fromSite}`;
        } else {
           if (portal !== null) {
          url = process.env.VUE_APP_API_BASE+`/get-common-menu?agent_id=${app.agent_id}&group_id=${app.group_id}&portal_id=${portal}&eprocess=${app.eprocess}&open_type=${app.open_type}`;
        } else {
          url = process.env.VUE_APP_API_BASE+`/get-common-menu?agent_id=${app.agent_id}&group_id=${app.group_id}&eprocess=${app.eprocess}&open_type=${app.open_type}`;
        }
          // url = process.env.VUE_APP_API_BASE+`/get-group-menu?group_id=${app.group_id}`;
        }
      }
      axios.get(url).then(function(response)
      {
        if (response.data.status === 'success') {
          console.log('****** inside getMenus success ******')
          response.data.data.forEach( (item) => {
            console.log('****** inside getMenus for loop ******')
            app.menuArray.push(item);
            if (item == 'dental') {
              console.log('****** inside getMenus for loop  dental ******', item)
              app.menus.dental = 'Dental';
            }
            if (item == 'vision') {
              app.menus.vision = 'Vision';
            }
            if (item == 'medical') {
              app.menus.medical = 'Medical';
            }
            if (item == 'limitedmed') {
              app.menus.limitedmed = 'Limited Med';
            }
            if (item == 'term_life') {
              app.menus.term_life = 'Term Life';
            }
            if (item == 'supplemental') {
              app.menus.supplemental = 'Supplemental';
            }
            if (item == 'lifestyle') {
              app.menus.lifestyle = 'Lifestyle';
            }
            if (item == 'rx') {
              app.menus.rx = 'RX';
            }
            if (item == 'di') {
              app.menus.di = 'Disability Income';
            }
            if (item == 'critical') {
              app.menus.critical = 'Critical Illness'
            }
            if (item == 'accident') {
              app.menus.accident = 'Accident';
            }
            if (item == 'hospital') {
              app.menus.hospital = 'Hospital';
            }
            if (item == 'pet') {
              app.menus.pet = 'PET';
            }
          });
          app.menuList.push(app.menus)
        }
      })
          .catch(function (error) {
            console.log(error);
          });
    },
      menuItem: function(val) {
      let app = this
      /****** Please don`t delete this code it may be used later
      /*  if (val === 'Dental') {
          app.menuName = 'dental';
        }
        if (val === 'Vision') {
          app.menuName ='vision';
        }
        if (val === 'Medical') {
          app.menuName = 'medical';
        }
        if (val === 'Limited Med') {
          app.menuName = 'limitedmed';
        }
        if (val === 'Term Life') {
          app.menuName = 'term_life';
        }
        if (val === 'Supplemental') {
          app.menuName = 'supplemental';
        }
        if (val === 'Lifestyle') {
          app.menuName = 'lifestyle';
        }
        if (val === 'RX') {
          app.menuName = 'rx';
        }
        if (val === 'Disability Income') {
          app.menuName = 'di';
        }
        if (val === 'Critical Illness') {
          app.menuName  = 'critical'
        }
        if (val === 'Accident') {
          app.menuName  = 'accident';
        }
        if (val === 'Hospital') {
          app.menuName  = 'hospital';
        }
        if (val === 'PET') {
          app.menuName  = 'pet';
        } *************/
        if (val === 'dental') {
          app.menuName = 'dental';
        }
        if (val === 'vision') {
          app.menuName ='vision';
        }
        if (val === 'medical') {
          app.menuName = 'medical';
        }
        if (val === 'limitedmed') {
          app.menuName = 'limitedmed';
        }
        if (val === 'term_life') {
          app.menuName = 'term_life';
        }
        if (val === 'supplemental') {
          app.menuName = 'supplemental';
        }
        if (val === 'lifestyle') {
          app.menuName = 'lifestyle';
        }
        if (val === 'rx') {
          app.menuName = 'rx';
        }
        if (val === 'di') {
          app.menuName = 'di';
        }
        if (val === 'critical') {
          app.menuName  = 'critical'
        }
        if (val === 'accident') {
          app.menuName  = 'accident';
        }
        if (val === 'hospital') {
          app.menuName  = 'hospital';
        }
        if (val === 'pet') {
          app.menuName  = 'pet';
        }
        app.menuSelectedItem = val;
        localStorage.setItem('menuSelectedItem', val)
       this.$root.$emit('menuData', app.menuName);
       this.$router.push({name: 'plans', query: {type: app.menuName}})
    },
    logout: function() {
      localStorage.clear();
      this.$router.push('/');
    },
    cartPage: function () {
      this.$router.push({name: 'cart'});
    },
    cartCount: function (id) {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE + '/get-cart-count', {
        params: {
          enrollment_id: id,
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.cartItems = response.data.data.result;
              localStorage.setItem('cartCount', app.cartItems)
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getAgentHeaderInfo: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE + '/get-agent-landing-page-info', {
        params: {
          agent_id: app.agent_id
        },
      })
          .then(function (response) {
            if (response.data.data.logo !== null) {
              app.logo = response.data.data.logo;
            }
            app.phone = response.data.data.display_phone;
            app.email = response.data.data.display_email;
            app.tagline = response.data.data.tagline;
            app.about_name = response.data.data.display_about_name;
            app.display_name = response.data.data.display_about_name;
            if (response.data.data.image !== null) {
              app.image = response.data.data.image;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getAgentAboutInfo: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE + '/get-agent-about-page-info', {
        params: {
          agent_id: app.agent_id
        },
      })
          .then(function (response) {
            app.about_content = response.data.data.agent_bio;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getGroupHeaderInfo: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE + '/get-group-landing-page-info', {
        params: {
          group_id: app.group_id
        },
      })
          .then(function (response) {
            if (response.data.data.logo !== null) {
              app.logo = response.data.data.logo;
            }
            app.phone = response.data.data.display_phone;
            app.email = response.data.data.display_email;
            app.tagline = response.data.data.tagline;
            app.about_name = response.data.data.display_about_name;
            app.display_name = response.data.data.display_about_name;
            if (response.data.data.image !== null) {
              app.image = response.data.data.image;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getGroupAboutInfo: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE + '/get-group-about-page-info', {
        params: {
          group_id: app.group_id
        },
      })
          .then(function (response) {
            app.about_content = response.data.data.gweb_aboutus;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    routeToLink (value) {
      location.replace(value);
    }
  },
  // Event bus library build in method listener object
  listener: {
    getMenuList () {
      this.menuArray = []
      this.getMenus()
      this.portal_id = window.localStorage.getItem('portal_id');
    },
        'FromDirect'(){
      let app = this;
    app.group_id = window.localStorage.getItem('group_id');
    app.portal_id = window.localStorage.getItem('portal_id');
    app.agent_id = window.localStorage.getItem('agent_id');
    app.getMenus();
    if (app.open_type === 'group' || app.eprocess === 'group') {
      app.getGroupHeaderInfo();
      app.getGroupAboutInfo();
    } else {
    app.getAgentHeaderInfo();
      app.getAgentAboutInfo();
    }
    },
  }
}
</script>
