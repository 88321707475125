<template>
  <div class="main_wrapper product_list cart_page agent_landing plan_details informations" v-if="this.$router.name=='error'">
    <error-page></error-page>
  </div>
  <div class="main_wrapper product_list cart_page agent_landing plan_details informations" v-else>
    <site-header v-if="include_extra"></site-header>
    <router-view></router-view>
    <enrollible-home v-if="isOpenCommonEnrollment && siteEnrollible"></enrollible-home>
<!--    <open-page v-if="landing_type == 'user' && !isOpenEnrollment"></open-page>-->
    <agent-open-page v-else-if="landing_type == 'agent' && isOpenEnrollment"></agent-open-page>
    <group-open-page v-else-if="landing_type == 'group' && isOpenEnrollment"></group-open-page>
    <site-footer v-if="include_extra"></site-footer>
  </div>
</template>

<script>
  import ErrorPage from '../components/404'
  // import OpenPage from "../views/OpenPage"
  import AgentOpenHome from "../views/AgentOpenHome";
  import GroupOpenHome from "../views/GroupOpenHome";
  import EnrollibleHome from "./EnrollibleHome";
  import SiteHeader from "../components/SiteHeader";
  import SiteFooter from '../components/SiteFooter';
  export default {
    name: "Home",
    components: {
      // 'open-page' : OpenPage,
      'agent-open-page' : AgentOpenHome,
      'group-open-page' : GroupOpenHome,
      'error-page' : ErrorPage,
      'enrollible-home': EnrollibleHome,
      'site-header': SiteHeader,
      'site-footer': SiteFooter,
    },
    data: function() {
      return {
        landing_type : '',
        include_extra: true,
        include_old: false,
      }
    },
    computed: {
      isOpenEnrollment() {
        if (this.$route.name == "main") {
          return true;
        } else {
          return false
        }
      },
      isOpenCommonEnrollment() {
        if (this.$route.name == "main") {
          if (this.$route.params.landing) {
            return false;
          } else {
            return true
          }
        } else {
          return false
        }
      },
      siteEnrollible () {
        if (process.env.VUE_APP_SITE_NAME === 'enrollible') {
          return true
        } else {
          return false
        }
      }
    },
    mounted() {
      console.log('parent called');
      let app = this;
      app.landing_type = window.localStorage.getItem('open_type');
      app.include_extra = app.$route.name !== 'error' && app.$route.name !== 'continue-enrollment' && !app.isOpenCommonEnrollment;
      app.include_old = app.$route.name === 'continue-enrollment';
      app.routeToOptionPlus();
    },
    methods: {
      routeToOptionPlus () {
        if (this.$route.name == "main") {
          console.log('***** inside option plus site ****')
          if (this.$route.params.landing) {
            return false;
          } else {
            if (process.env.VUE_APP_SITE_NAME === 'optionPlus') {
              location.replace(process.env.VUE_APP_REDIRECT_OPT);
            }
            return true
          }
        } else {
          return false
        }
      }
    }

  }
</script>
